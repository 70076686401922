import { handleActions } from 'redux-actions'
import { fromJS, Map } from 'immutable'

import { StepTypes } from 'static/constants'
import * as AppActions from 'actions/app'
import * as Actions from 'actions/charts'
import * as ConfigActions from 'actions/config'

import allowedChartConfigs, { addCustomKpis, addAiEntities } from 'static/allowed_chart_configs'

const createNewChart = () => fromJS({
  firstLevel: 'mediaReviewCodes',
  type: 'bar'
})

export const initialState = fromJS({
  allowedChartConfigs,
  isRunning: false,
  isEditing: false,
  selectedChart: createNewChart(),
  selectedChartIndex: null,
  selectedChartStepType: StepTypes.CHARTS,
  showChartDialog: false
})

export default handleActions({
  [Actions.setSelectedChart]: (state, { payload: chart }) => (
    state.set('selectedChart', chart.update('data', data => data || state.getIn(['selectedChart', 'data'])))
  ),
  [Actions.setSelectedChartOpt]: (state, { payload: { key, value } }) => (
    state.updateIn(['selectedChart', 'opts'], opts => (opts || Map({})).set(key, value))
  ),

  [Actions.setSelectedChartStepType]: (state, { payload: stepType }) => state.set('selectedChartStepType', stepType),
  [Actions.setSelectedChartData]: (state, { payload: data }) => state.setIn(['selectedChart', 'data'], fromJS(data)),
  [Actions.setSelectedChartLoading]: (state, { payload }) => state.setIn(['selectedChart', 'loading'], payload),

  [Actions.showChartDialog]: (state, { payload }) => {
    let selectedChart = state.get('selectedChart')
    let selectedChartIndex = null

    if (payload) {
      const { chart, index } = payload
      selectedChart = chart
      selectedChartIndex = index
    }

    return state.merge({
      showChartDialog: true,
      selectedChart,
      selectedChartIndex,
      selectedChartStepType: StepTypes.CHARTS
    })
  },
  [Actions.hideChartDialog]: state => state.set('showChartDialog', false),

  [Actions.aggregationsRequestStart]: state => state.set('isRunning', true),
  [Actions.aggregationsRequestSuccess]: state => state.set('isRunning', false),
  [Actions.aggregationsRequestError]: state => state.set('isRunning', false),

  [Actions.toggleEditing]: state => state.set('isEditing', !state.get('isEditing')),
  [Actions.setNormalMode]: state => state.set('isEditing', false),

  [ConfigActions.setStatics]: (state, { payload }) => (
    state.update('allowedChartConfigs', configs => addAiEntities(addCustomKpis(configs, payload.customKpis), payload.aiEntities))
  ),

  [AppActions.resetState]: () => initialState
}, initialState)
