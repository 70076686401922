import { fromJS } from 'immutable'
import camelcase from 'camelcase'
import pixelWidth from 'string-pixel-width'
import { rangeToDates, calculateIntervals } from 'utils/date'

export const sortCharts = (dashboard, sorter, reverse = true) => {
  const charts = dashboard.get('charts')

  const result = charts.reduce((acc, value, key) => {
    const layouts = dashboard.getIn(['layouts', key])
    let sorted = value.reduce(
      (a, v, i) => a.push(fromJS({
        chart: v,
        layout: layouts.get(i)
      })),
      fromJS([])
    )
      .sortBy(sorter || (v => v.getIn(['layout', 'y'])))

    if (reverse) {
      sorted = sorted.reverse()
    }

    return acc
      .setIn(['charts', key], sorted.map(s => s.get('chart')))
      .setIn(['layouts', key], sorted.map(s => fromJS({
        x: s.getIn(['layout', 'x']),
        y: s.getIn(['layout', 'y']),
        w: s.getIn(['layout', 'w']),
        h: s.getIn(['layout', 'h'])
      })))
  }, dashboard)

  return result
}

export const recalculateLayout = (layout, breakpoint) => {
  if (breakpoint === 'lg') {
    return fromJS(layout.map((l, i) => fromJS({
      x: (i % 3) * 4,
      y: Math.floor(i / 3) * 4,
      w: 4,
      h: 4
    })))
  }

  if (breakpoint === 'md') {
    return fromJS(layout.map((l, i) => fromJS({
      x: (i % 2) * 6,
      y: Math.floor(i / 2) * 4,
      w: 6,
      h: 4
    })))
  }

  return fromJS(layout.map((l, i) => fromJS({
    x: 0,
    y: i * 4,
    w: 12,
    h: 4
  })))
}

const camelId = id => (
  id < 0 ? `minus${id}` : id
)

const getDataKeySuffix = el => camelcase(`${camelId(el.get('id'))}_${el.get('name')}`)
export const getDataKey = el => camelcase(`count_${getDataKeySuffix(el)}`)

export const toStackedData = data => {
  const dataKeys = data.map(d => d.get('stack').map(getDataKey)).flatten(true).toSet()

  return data.map(d => {
    let newD = d

    newD.get('stack').forEach(s => {
      newD = newD
        .delete('fill')
        .set(getDataKey(s), s.get('count'))
    })

    dataKeys.forEach(dataKey => {
      if (!newD.has(dataKey)) {
        newD = newD.set(dataKey, 0)
      }
    })

    return newD
  })
}

export const hasStack = data => data && data.some && data.some(d => d.stack !== undefined && d.stack !== null && d.stack.length > 0)

export const getAllStacks = data => (
  data.reduce(
    (acc, d) => (
      d.get('stack', fromJS([])).reduce(
        (a, s) => {
          if (!a.has(s.get('id').toString())) {
            return a.set(s.get('id').toString(), s)
          }

          return a
        },
        acc
      )
    ),
    fromJS({})
  )
    .valueSeq()
    .toList()
)

export const copyFromDisplaySize = (dashboard, from, to) => {
  const sorted = sortCharts(dashboard, v => `${v.getIn(['layout', 'y'])}${v.getIn(['layout', 'x'])}`, false)

  return dashboard
    .setIn(['layouts', to], recalculateLayout(sorted.getIn(['charts', from]), to))
    .setIn(['charts', to], sorted.getIn(['charts', from]))
}

export const fillDashboard = dashboard => {
  if (!dashboard) {
    return dashboard
  }

  const layouts = dashboard.get('layouts')

  if (!layouts) {
    return dashboard
  }

  const breakpoints = ['lg', 'md', 'sm', 'xs']
  let result = dashboard

  layouts.forEach(
    (values, breakpoint) => {
      if (values.isEmpty()) {
        const index = breakpoints.indexOf(breakpoint)
        let newBreakpoints = breakpoints

        if (index) {
          newBreakpoints = breakpoints.slice(0, index).reverse().concat(breakpoints.slice(index + 1, -1))
        }

        const firstNonEmptyBreakpoint = newBreakpoints.find(b => !(layouts.get(b) || fromJS([])).isEmpty())

        if (firstNonEmptyBreakpoint) {
          result = copyFromDisplaySize(result, firstNonEmptyBreakpoint, breakpoint)
        }
      }
    }
  )

  return result
}

const maxTickWidthPercent = 0.85
const maxTickWidthAutoPercent = 1.2
const tickFontSizes = [14, 13, 12]

export const getAxesTickFontSizeAndInterval = (chartData, width) => {
  const allXAxisTicks = chartData.map(({ name }) => name).join(' ')

  let fontSize
  let lastPercent
  tickFontSizes.forEach(size => {
    const stringWidth = pixelWidth(allXAxisTicks, { size })
    lastPercent = stringWidth / width

    if (lastPercent < maxTickWidthPercent && !fontSize) {
      fontSize = size
    }
  })

  let interval = 0

  if (!fontSize) {
    interval = 1
    fontSize = 14
  }

  if (lastPercent >= maxTickWidthAutoPercent) {
    interval = undefined
  }

  return [interval, fontSize]
}

export const getMaxLabelWidth = (data, size = 14) => {
  const allNames = data.map(d => [d.name].concat((d.stack || []).map(s => s.name))).flat()
  allNames.sort((a, b) => b.length - a.length)

  return pixelWidth(allNames[0], { size }) + 50
}

export const updateInterval = chart => {
  const currentInterval = chart.get('dateInterval')

  if (!currentInterval) {
    return chart
  }

  const dateFrom = chart.get('dateFrom')
  const dateTo = chart.get('dateTo')

  if (dateFrom && dateTo) {
    const { dateInterval } = calculateIntervals(currentInterval, dateFrom, dateTo)

    return chart.set('dateInterval', dateInterval)
  }

  const { from, to } = rangeToDates(chart.get('dateRange'))
  const { dateInterval } = calculateIntervals(currentInterval, from, to, chart.get('dateRange'))

  return chart.set('dateInterval', dateInterval)
}

export const sortStacksWithOthers = stacks => {
  if (stacks.size && stacks.first().has('sortKey')) {
    return stacks.sortBy(s => s.get('sortKey'))
  }

  return stacks.sort((a, b) => {
    if (a.get('id') === -1) {
      return 1
    }

    if (b.get('id') === -1) {
      return -1
    }

    return b.get('count') - a.get('count')
  })
}

// let's get dirty...
export const sortStacksWithOthersForTooltip = stacks => (
  stacks.sort((a, b) => {
    if (a.get('dataKey', '').includes('Minus1')) {
      return 1
    }

    if (b.get('dataKey', '').includes('Minus1')) {
      return -1
    }

    return b.get('value') - a.get('value')
  })
)
