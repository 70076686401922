import { fromJS } from 'immutable'
import camelcase from 'camelcase'
import { NewsPageModules, ChartDataSources, ChartTypes } from 'static/constants'

const enrichedNewsModules = fromJS([NewsPageModules.NEWS, NewsPageModules.ANALYSIS, NewsPageModules.PROFILE_MONITORING])
const newsOnly = fromJS([NewsPageModules.NEWS])
const profileMonitoringOnly = fromJS([NewsPageModules.PROFILE_MONITORING])
const noProfileMonitoring = fromJS([NewsPageModules.NEWS, NewsPageModules.ANALYSIS, NewsPageModules.NEWS_POOL])
const analysisOnly = fromJS([NewsPageModules.ANALYSIS])

const defaultThirdLevels = [
  {
    name: 'buzz'
  },
  {
    name: 'ave',
    moduleNames: noProfileMonitoring
  },
  {
    name: 'reach'
  },
  {
    name: 'karma'
  },
  {
    name: 'interactions'
  },
  {
    name: 'interactionRate'
  },
  {
    name: 'visits',
    moduleNames: noProfileMonitoring
  },
  {
    name: 'uniqueUsers',
    moduleNames: noProfileMonitoring
  },
  {
    name: 'mozDomainAuthority',
    moduleNames: noProfileMonitoring
  },
  {
    name: 'mozPageAuthority',
    moduleNames: noProfileMonitoring
  },
  {
    name: 'mozSpamScore',
    moduleNames: noProfileMonitoring
  },
  {
    name: 'circulation',
    moduleNames: noProfileMonitoring
  },
  {
    name: 'mentionsCount',
    moduleNames: analysisOnly
  },
  {
    name: 'identityCount'
  },
  {
    name: 'newsguardScore',
    moduleNames: noProfileMonitoring
  },
  {
    name: 'views'
  },
  {
    name: 'statementTonality',
    moduleNames: analysisOnly
  }
]

const defaultSecondLevels = [
  {
    name: 'tonalities',
    moduleNames: newsOnly
  },
  {
    name: 'sentiments'
  },
  {
    name: 'statementTonalities',
    moduleNames: analysisOnly
  }
]

const defaultBarChartSecondLevels = [
  {
    name: 'tonalities',
    moduleNames: newsOnly
  },
  {
    name: 'sentiments'
  },
  {
    name: 'mediaReviewCodes',
    moduleNames: newsOnly
  },
  {
    name: 'customTags',
    moduleNames: enrichedNewsModules
  },
  {
    name: 'channels'
  },
  {
    name: 'domains'
  },
  {
    name: 'countries'
  },
  {
    name: 'languages'
  },
  {
    name: 'hashtags'
  },
  {
    name: 'states'
  },
  {
    name: 'mediaTypes'
  },
  {
    name: 'analysisCodes',
    moduleNames: analysisOnly
  },
  {
    name: 'statementTonalities',
    moduleNames: analysisOnly
  },
  {
    name: 'newsguardScoreRanges',
    moduleNames: noProfileMonitoring
  },
  {
    name: 'newsguardRanks',
    moduleNames: noProfileMonitoring
  },
  {
    name: 'newsguardOrientations',
    moduleNames: noProfileMonitoring
  },
  {
    name: 'interfaces'
  },
  {
    name: 'suppliers'
  }
]

const timelineSecondLevels = defaultBarChartSecondLevels

const defaultBarChartSecondLevelsNoTonality = defaultBarChartSecondLevels.filter(s => !s.name.match(/onalit|entiment/))

const defaultChartConfig = fromJS({
  secondLevels: defaultSecondLevels,
  thirdLevels: defaultThirdLevels
})

const defaultBarChartConfig = fromJS({
  secondLevels: defaultBarChartSecondLevels,
  thirdLevels: defaultThirdLevels
})

const defaultChartConfigNoTonality = {
  secondLevels: [],
  thirdLevels: defaultThirdLevels
}

const defaultBarChartConfigNoTonality = {
  secondLevels: defaultBarChartSecondLevelsNoTonality,
  thirdLevels: defaultThirdLevels
}

export const defaultBucketConfig = fromJS({
  chartTypes: {
    [ChartTypes.BAR]: defaultBarChartConfig,
    [ChartTypes.RADIAL_BAR]: defaultBarChartConfig,
    [ChartTypes.HORIZONTAL_BAR]: defaultBarChartConfig,
    [ChartTypes.BUBBLE]: defaultBarChartConfig,
    [ChartTypes.PIE]: defaultChartConfig,
    [ChartTypes.FUNNEL]: defaultChartConfig,
    [ChartTypes.TAG_CLOUD]: defaultChartConfig,
    [ChartTypes.TREEMAP]: defaultChartConfig,
    [ChartTypes.RADAR]: defaultChartConfig,
    [ChartTypes.LINE]: defaultChartConfig,
    [ChartTypes.AREA]: defaultChartConfig,
    [ChartTypes.HEATMAP]: defaultBarChartConfig
  }
})

const defaultGaBucketConfig = defaultBucketConfig.set('dataSources', ChartDataSources.GOOGLE_ANALYTICS)
const defaultYoutubeAnalyticsBucketConfig = defaultBucketConfig.set('dataSources', ChartDataSources.YOUTUBE_ANALYTICS)

const noDeeperLevels = {
  secondLevels: [],
  thirdLevels: []
}

const createDefaultBucketConfig = moduleNames => defaultBucketConfig.set('moduleNames', moduleNames)

const defaultNumberConfig = fromJS({
  isNumber: true,
  chartTypes: {
    rectangle: noDeeperLevels
  }
})

const createDefaultNumberConfig = moduleNames => defaultNumberConfig.set('moduleNames', moduleNames)

const defaultTonalityConfig = fromJS({
  isNumber: true,
  chartTypes: {
    rectangle: noDeeperLevels,
    gauge: noDeeperLevels
  }
})

const createDefaultTonalityConfig = moduleNames => defaultTonalityConfig.set('moduleNames', moduleNames)

const countriesConfig = createDefaultBucketConfig(noProfileMonitoring).setIn(['chartTypes', ChartTypes.WORLD_MAP], defaultChartConfig)
const statesConfig = createDefaultBucketConfig(noProfileMonitoring).setIn(['chartTypes', ChartTypes.STATE_MAP], defaultChartConfig)

export const addCustomKpis = (configs, customKpis) => {
  if (!customKpis || !customKpis.length) {
    return configs
  }

  const numberConfigs = fromJS(customKpis.reduce((acc, kpi) => acc.set(camelcase(kpi.key), defaultNumberConfig), fromJS({})))
  const thirdLevels = fromJS(customKpis.map(c => ({ name: camelcase(c.key) })))

  return configs.merge(numberConfigs).map(value => {
    if (value.get('chartTypes')) {
      return value.update('chartTypes', chartTypes => chartTypes.map(v => {
        if (v.get('thirdLevels') && !v.get('thirdLevels').isEmpty()) {
          return v.update('thirdLevels', t => t.concat(thirdLevels))
        }

        return v
      }))
    }

    return value
  })
}

export const addAiEntities = (configs, aiEntities) => {
  if (!aiEntities || !aiEntities.length) {
    return configs
  }

  const firstLevels = fromJS(
    aiEntities.reduce((acc, kpi) => acc.set(camelcase(kpi.key), createDefaultBucketConfig(enrichedNewsModules)), fromJS({}))
  )

  const secondLevels = fromJS(aiEntities.map(c => ({
    name: camelcase(c.key),
    moduleNames: enrichedNewsModules
  })))

  return configs.merge(firstLevels).map(value => {
    if (value.get('chartTypes')) {
      return value.update('chartTypes', chartTypes => chartTypes.map(v => {
        if (v.get('secondLevels') && !v.get('secondLevels').isEmpty()) {
          return v.update('secondLevels', t => t.concat(secondLevels))
        }

        return v
      }))
    }

    return value
  })
}

export default fromJS({
  buzz: defaultNumberConfig,
  reach: defaultNumberConfig,
  karma: defaultNumberConfig,
  views: defaultNumberConfig,

  sentiment: defaultTonalityConfig,

  interactions: defaultNumberConfig,
  interactionRate: defaultNumberConfig,

  ave: createDefaultNumberConfig(noProfileMonitoring),
  circulation: createDefaultNumberConfig(noProfileMonitoring),
  visits: createDefaultNumberConfig(noProfileMonitoring),
  uniqueUsers: createDefaultNumberConfig(noProfileMonitoring),
  mozDomainAuthority: createDefaultNumberConfig(noProfileMonitoring),
  mozPageAuthority: createDefaultNumberConfig(noProfileMonitoring),
  mozSpamScore: createDefaultNumberConfig(noProfileMonitoring),
  ownerBuzz: createDefaultNumberConfig(profileMonitoringOnly),
  audienceBuzz: createDefaultNumberConfig(profileMonitoringOnly),
  newsguardScore: createDefaultNumberConfig(noProfileMonitoring),
  mentionsCount: createDefaultNumberConfig(analysisOnly),

  statementTonality: createDefaultTonalityConfig(analysisOnly),
  statementTonalities: createDefaultBucketConfig(analysisOnly),

  tonality: createDefaultTonalityConfig(newsOnly),

  timeline: {
    isTimeline: true,
    chartTypes: {
      [ChartTypes.HORIZONTAL_BAR]: {
        secondLevels: timelineSecondLevels,
        thirdLevels: defaultThirdLevels
      },
      [ChartTypes.LINE]: {
        secondLevels: timelineSecondLevels,
        thirdLevels: defaultThirdLevels
      },
      [ChartTypes.AREA]: {
        secondLevels: timelineSecondLevels,
        thirdLevels: defaultThirdLevels
      },
      [ChartTypes.HEATMAP]: {
        secondLevels: timelineSecondLevels,
        thirdLevels: defaultThirdLevels
      }
    }
  },

  savedSearchesTimeline: {
    isSavedSearchesTimeline: true,
    chartTypes: {
      [ChartTypes.HORIZONTAL_BAR]: {
        secondLevels: [
          {
            name: 'savedSearches'
          }
        ],
        thirdLevels: defaultThirdLevels
      },
      [ChartTypes.LINE]: {
        secondLevels: [
          {
            name: 'savedSearches'
          }
        ],
        thirdLevels: defaultThirdLevels
      },
      [ChartTypes.AREA]: {
        secondLevels: [
          {
            name: 'savedSearches'
          }
        ],
        thirdLevels: defaultThirdLevels
      }
    }
  },

  tonalities: {
    moduleNames: newsOnly,
    chartTypes: {
      [ChartTypes.BAR]: defaultBarChartConfigNoTonality,
      [ChartTypes.BUBBLE]: defaultBarChartConfigNoTonality,
      [ChartTypes.PIE]: defaultChartConfigNoTonality,
      [ChartTypes.FUNNEL]: defaultChartConfigNoTonality,
      [ChartTypes.TREEMAP]: defaultChartConfigNoTonality,
      [ChartTypes.RADAR]: defaultChartConfigNoTonality,
      [ChartTypes.HEATMAP]: defaultChartConfigNoTonality
    }
  },

  sentiments: {
    moduleNames: noProfileMonitoring,
    chartTypes: {
      [ChartTypes.BAR]: defaultBarChartConfigNoTonality,
      [ChartTypes.BUBBLE]: defaultBarChartConfigNoTonality,
      [ChartTypes.PIE]: defaultChartConfigNoTonality,
      [ChartTypes.FUNNEL]: defaultChartConfigNoTonality,
      [ChartTypes.TREEMAP]: defaultChartConfigNoTonality,
      [ChartTypes.RADAR]: defaultChartConfigNoTonality,
      [ChartTypes.HEATMAP]: defaultChartConfigNoTonality
    }
  },

  countries: countriesConfig,

  publications: defaultBucketConfig,
  channels: defaultBucketConfig,
  languages: createDefaultBucketConfig(noProfileMonitoring),
  mediaTopics: createDefaultBucketConfig(noProfileMonitoring),
  mediaTypes: createDefaultBucketConfig(noProfileMonitoring),
  analysisCodes: createDefaultBucketConfig(analysisOnly),
  hashtags: defaultBucketConfig,
  mentions: defaultBucketConfig,
  emojis: defaultBucketConfig,
  influencers: defaultBucketConfig,
  states: statesConfig,
  outlinks: defaultBucketConfig,
  outlinkDomains: defaultBucketConfig,
  domains: defaultBucketConfig,
  savedSearches: defaultBucketConfig,
  interfaces: defaultBucketConfig,
  suppliers: defaultBucketConfig,

  customTags: createDefaultBucketConfig(enrichedNewsModules),
  mediaReviewCodes: createDefaultBucketConfig(newsOnly),

  authorSexes: createDefaultBucketConfig(noProfileMonitoring),
  authorCountries: createDefaultBucketConfig(noProfileMonitoring),
  authorCities: createDefaultBucketConfig(noProfileMonitoring),
  authorBirthdateRanges: createDefaultBucketConfig(noProfileMonitoring),

  entities: createDefaultBucketConfig(enrichedNewsModules),
  entitiesPerson: createDefaultBucketConfig(enrichedNewsModules),
  entitiesLocation: createDefaultBucketConfig(enrichedNewsModules),
  entitiesOrganization: createDefaultBucketConfig(enrichedNewsModules),
  entitiesProduct: createDefaultBucketConfig(enrichedNewsModules),
  entitiesTopic: createDefaultBucketConfig(enrichedNewsModules),

  newsguardScoreRanges: createDefaultBucketConfig(noProfileMonitoring),
  newsguardRanks: createDefaultBucketConfig(noProfileMonitoring),
  newsguardOrientations: createDefaultBucketConfig(noProfileMonitoring),

  newsFeed: {
    isNewsFeed: true,
    chartTypes: {
      [ChartTypes.NEWS_FEED]: noDeeperLevels,
      [ChartTypes.NEWS_TICKER]: noDeeperLevels
    }
  },

  topInfluencers: {
    isTopInfluencers: true,
    chartTypes: {
      [ChartTypes.INFLUENCER_FEED]: noDeeperLevels
    }
  },

  topPublications: {
    isTopPublications: true,
    chartTypes: {
      [ChartTypes.PUBLICATION_FEED]: noDeeperLevels
    }
  },

  pageIdentitiesTimeline: {
    isPageIdentitiesTimeline: true,
    moduleNames: profileMonitoringOnly,
    chartTypes: {
      [ChartTypes.LINE]: {
        secondLevels: [
          {
            name: 'pageIdentities'
          }
        ],
        thirdLevels: [
          {
            name: 'buzz'
          },
          {
            name: 'interactionRates'
          },
          {
            name: 'interactions'
          },
          {
            name: 'comments'
          },
          {
            name: 'reactions'
          },
          {
            name: 'shares'
          },
          {
            name: 'commentsReactions'
          },
          {
            name: 'commentsShares'
          },
          {
            name: 'reactionsShares'
          },
          {
            name: 'followers'
          }
        ]
      }
    }
  },

  pageIdentities: {
    isPageIdentities: true,
    chartTypes: {
      [ChartTypes.PAGE_IDENTITY_FEED]: noDeeperLevels
    },
    moduleNames: profileMonitoringOnly
  },

  imageFeed: {
    isImageFeed: true,
    chartTypes: {
      [ChartTypes.IMAGE_FEED]: noDeeperLevels
    }
  },

  // GOOGLE ANALYTICS; ONLY USED FOR CHART TYPES
  'ga:deviceCategory': defaultGaBucketConfig,
  'ga:source': defaultGaBucketConfig,
  'ga:pagePath': defaultGaBucketConfig,

  // YOUTUBE ANALYTICS; ONLY USED FOR CHART TYPES
  youtubeAnalyticsVideos: defaultYoutubeAnalyticsBucketConfig,
  operatingSystem: defaultYoutubeAnalyticsBucketConfig,
  insightTrafficSourceType: defaultYoutubeAnalyticsBucketConfig,
  deviceType: defaultYoutubeAnalyticsBucketConfig
})
