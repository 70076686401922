import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'

import { ChartDataSources, SearchFields } from 'static/constants'
import * as Actions from 'actions/page_identities'
import * as AppActions from 'actions/app'
import { defaultChartOpts } from 'utils/page_identities'

const hashtagsChartOpts = {
  minSize: 20
}

const mentionsChartOpts = {
  minSize: 20
}

export const initialState = fromJS([
  {
    firstLevel: 'pageIdentitiesTimeline',
    secondLevel: 'pageIdentities',
    thirdLevel: 'buzz',
    dataSource: ChartDataSources.PRESSRELATIONS_NEWS,
    type: 'area',
    label: 'BUZZ',
    opts: defaultChartOpts,

    searchField: SearchFields.IDENTITIES,
    isPageIdentitiesChart: true
  },
  {
    firstLevel: 'pageIdentitiesTimeline',
    secondLevel: 'pageIdentities',
    thirdLevel: 'interactionRates',
    dataSource: ChartDataSources.PRESSRELATIONS_NEWS,
    type: 'area',
    label: 'INTERACTION RATES',
    opts: { ...defaultChartOpts, addPercentSign: true, allowDecimals: true },

    searchField: SearchFields.IDENTITIES,
    isPageIdentitiesChart: true
  },
  {
    firstLevel: 'pageIdentitiesTimeline',
    secondLevel: 'pageIdentities',
    thirdLevel: 'interactions',
    dataSource: ChartDataSources.PRESSRELATIONS_NEWS,
    type: 'area',
    label: 'INTERACTIONS',
    opts: defaultChartOpts,

    searchField: SearchFields.IDENTITIES,
    renderInteractionsOptions: true,
    isPageIdentitiesChart: true
  },
  {
    firstLevel: 'pageIdentitiesTimeline',
    secondLevel: 'pageIdentities',
    thirdLevel: 'followers',
    dataSource: ChartDataSources.PRESSRELATIONS_NEWS,
    type: 'area',
    label: 'FOLLOWERS',
    opts: defaultChartOpts,

    searchField: SearchFields.IDENTITIES,
    isPageIdentitiesChart: true
  },
  {
    firstLevel: 'emojis',
    dataSource: ChartDataSources.PRESSRELATIONS_NEWS,
    type: 'tagCloud',
    label: 'EMOJIS',
    opts: defaultChartOpts,

    searchField: SearchFields.EMOJIS
  },
  {
    firstLevel: 'hashtags',
    dataSource: ChartDataSources.PRESSRELATIONS_NEWS,
    type: 'tagCloud',
    label: 'HASHTAGS',
    opts: { ...defaultChartOpts, ...hashtagsChartOpts },

    searchField: SearchFields.HASHTAGS
  },
  {
    firstLevel: 'mentions',
    dataSource: ChartDataSources.PRESSRELATIONS_NEWS,
    type: 'tagCloud',
    label: 'MENTIONS',
    opts: { ...defaultChartOpts, ...mentionsChartOpts },

    searchField: SearchFields.MENTIONS
  }
])

export default handleActions({
  [Actions.setChartData]: (state, { payload: { index, data } }) => state.mergeIn([index], {
    loading: false,
    data: fromJS(data)
  }),

  [Actions.setChartLoading]: (state, { payload: index }) => state.setIn([index, 'loading'], true),

  [AppActions.resetState]: () => initialState
}, initialState)
