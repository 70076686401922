import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as Actions from 'actions/themax'
import * as AppActions from 'actions/app'
import * as ConfigActions from 'actions/config'

const queryTypeLabelMapping = {
  topic_plan: 'search_in_topic_plans',
  publication_name: 'search_by_publication_name',
  publication_info: 'search_in_publication_info'
}

export const initialState = fromJS({
  moduleInitialized: false,
  activeFiltersCollapsed: true,
  showAdvancedSearch: false,
  showDateForm: false,
  queryType: 'topic_plan',
  searchBarLabel: 'search_in_topic_plans',
  searchRequestRunning: false,
  searchEditorialPlansRunning: false,
  searchPublicationsRunning: false,
  fetchMoreRequestRunning: false,
  tabView: 'editorial_plans',
  savedSearchRequestRunning: false,
  showDetailedViewDialog: false,
  bookmarkRequestRunningFor: null,
  excelExportRequestRunning: false,
  showWelcomeDialog: false,
  showOrderDialog: false,
  showOrderSuccessDialog: false,
  orderRequestRunning: false
})

export default handleActions({
  [Actions.setSelectedQueryType]: (state, { payload }) => {
    const newState = state.set('queryType', payload)

    if (queryTypeLabelMapping[payload]) {
      return newState.set('searchBarLabel', queryTypeLabelMapping[payload])
    }

    return state
  },
  [Actions.changeTabView]: (state, { payload }) => state
    .set('tabView', payload)
    .set('queryType', payload === 'media_profiles' ? 'publication_name' : 'topic_plan')
    .set('searchBarLabel', payload === 'media_profiles' ? queryTypeLabelMapping.publication_name : queryTypeLabelMapping.topic_plan),
  [Actions.toggleAdvancedSearch]: state => state.update('showAdvancedSearch', showAdvancedSearch => !showAdvancedSearch),
  [Actions.toggleDateForm]: state => state.update('showDateForm', showDateForm => !showDateForm),
  [Actions.toggleActiveFilters]: state => state.update('activeFiltersCollapsed', v => !v),
  [Actions.applyThemaxFilters]: state => state.set('showAdvancedSearch', false),
  [Actions.resetThemaxFilters]: state => state.set('showAdvancedSearch', false),
  [Actions.themaxSearchRequestStart]: state => state.set('searchRequestRunning', true),
  [combineActions(
    Actions.themaxSearchRequestSuccess,
    Actions.themaxSearchRequestError
  )]: state => state.set('fetchMoreRequestRunning', false),
  [combineActions(
    Actions.themaxFetchRelatedEditorialPlansSuccess,
    Actions.themaxFetchRelatedEditorialPlansError
  )]: state => state.set('searchRequestRunning', false).set('fetchMoreRequestRunning', false),
  [Actions.themaxSearchEditorialPlans]: state => state.set('searchEditorialPlansRunning', true),
  [combineActions(
    Actions.themaxSearchEditorialPlansSuccess,
    Actions.themaxSearchEditorialPlansError
  )]: state => state.set('searchEditorialPlansRunning', false).set('searchRequestRunning', false),
  [Actions.themaxSearchPublications]: state => state.set('searchPublicationsRunning', true),
  [combineActions(
    Actions.themaxSearchPublicationsSuccess,
    Actions.themaxSearchPublicationsError
  )]: state => state.set('searchPublicationsRunning', false).set('searchRequestRunning', false),

  [combineActions(
    Actions.searchThemaxSavedSearchesStart,
    Actions.saveThemaxSavedSearchStart
  )]: state => state.set('savedSearchRequestRunning', true),
  [combineActions(
    Actions.themaxFetchMoreEditorialPlansStart,
    Actions.themaxFetchRelatedEditorialPlansStart,
    Actions.themaxFetchMorePublicationsStart
  )]: state => state.set('fetchMoreRequestRunning', true),

  [combineActions(
    Actions.searchThemaxSavedSearchesSuccess,
    Actions.searchThemaxSavedSearchesError,
    Actions.saveThemaxSavedSearchSuccess,
    Actions.saveThemaxSavedSearchError
  )]: state => state.set('savedSearchRequestRunning', false),

  [combineActions(
    Actions.themaxSaveBookmarkStart,
    Actions.themaxDeleteBookmarkStart
  )]: (state, { payload: { editorialPlanId } }) => state.set('bookmarkRequestRunningFor', editorialPlanId),

  [combineActions(
    Actions.themaxSaveBookmarkSuccess,
    Actions.themaxDeleteBookmarkSuccess,
    Actions.themaxSaveBookmarkError,
    Actions.themaxDeleteBookmarkError
  )]: state => state.set('bookmarkRequestRunningFor', null),

  [Actions.setThemaxModuleInitialized]: (state, { payload }) => state.set('moduleInitialized', payload),
  [Actions.openDetailedViewDialog]: state => state.set('showDetailedViewDialog', true),
  [Actions.closeDetailedViewDialog]: state => state.set('showDetailedViewDialog', false),
  [Actions.exportEditorialPlansStart]: state => state.set('excelExportRequestRunning', true),
  [combineActions(
    Actions.exportEditorialPlansSuccess,
    Actions.exportEditorialPlansError
  )]: state => state.set('excelExportRequestRunning', false),

  [Actions.openWelcomeDialog]: state => state.set('showWelcomeDialog', true),
  [Actions.closeWelcomeDialog]: state => state.set('showWelcomeDialog', false),

  [Actions.openOrderDialog]: state => state.set('showOrderDialog', true),
  [Actions.closeOrderDialog]: state => state.set('showOrderDialog', false),

  [Actions.closeOrderSuccessDialog]: state => state.set('showOrderSuccessDialog', false),

  [ConfigActions.orderPaidVersionStart]: state => state.set('orderRequestRunning', true),
  [combineActions(
    ConfigActions.orderPaidVersionSuccess,
    ConfigActions.orderPaidVersionError
  )]: state => state.set('orderRequestRunning', false),

  [ConfigActions.orderPaidVersionSuccess]: state => state.set('showOrderSuccessDialog', true),

  [AppActions.resetState]: () => initialState
}, initialState)
