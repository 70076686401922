/* global window */
import { put, call, takeEvery, select, all } from 'redux-saga/effects'

import * as FilterActions from 'actions/filter'
import * as NewsActions from 'actions/news'
import * as Actions from 'actions/ui'
import * as AppActions from 'actions/app'
import * as Selectors from 'selectors'
import { rangeToDates } from 'utils/date'
import { scrollToTop } from 'utils/scroll'
import * as Api from 'api/bff'

export function* uiSetSortBy({ payload }) {
  yield put(NewsActions.setSortBy(payload))
  yield put(NewsActions.newsRequestStart())
}

export function* uiSetSortDirection({ payload }) {
  yield put(NewsActions.setSortDirection(payload))
  yield put(NewsActions.newsRequestStart())
}

export function* uiEnteredQuery({ payload: query }) {
  if (query !== '') {
    let isValid = false
    let isTooComplex = false

    try {
      const indexType = yield select(Selectors.getViewConfigIndexType)
      let queries = yield select(Selectors.getNewsFiltersNewsQueries)
      queries = queries.map(q => q.get('value')).push(query)

      const body = {
        queries,
        index_type: indexType
      }

      const { valid, tooComplex } = yield call(Api.validate, body)

      isValid = valid
      isTooComplex = tooComplex || false
    } catch (e) {
      isValid = false
    }

    if (isValid) {
      const type = yield select(Selectors.getSelectedQueryType)
      const i18n = yield select(Selectors.getI18n)
      const prefix = yield call(i18n.get, type)

      const queries = query.split(',').map(q => q.trim()).filter(q => q)

      for (let i = 0; i < queries.length; i += 1) {
        yield put(FilterActions.addNewsQuery({
          query: queries[i],
          type,
          prefix
        }))
      }

      yield put(NewsActions.newsRequestStart())
    } else {
      const i18n = yield select(Selectors.getI18n)

      yield put(AppActions.showAppMessage({
        text: i18n.get(isTooComplex ? 'too_complex_query' : 'invalid_query'),
        success: false
      }))
    }
  } else {
    yield put(NewsActions.newsRequestStart())
  }
}

export function* uiRemoveFilter({ payload: { index, field } }) {
  yield put(FilterActions.removeFilter({ index, field }))
  yield put(NewsActions.newsRequestStart())
}

export function* uiInvertFilter({ payload: { index, field } }) {
  yield put(FilterActions.invertFilter({ index, field }))
  yield put(NewsActions.newsRequestStart())
}

export function* uiAddFilters({ payload: filters }) {
  yield put(FilterActions.addFilters(filters))
  yield put(NewsActions.newsRequestStart())
  yield call(window.scrollTo, 0, 0)
}

export function* uiReplaceFilters({ payload: filters }) {
  yield put(FilterActions.replaceFilters(filters))
  yield put(NewsActions.newsRequestStart())
  yield call(window.scrollTo, 0, 0)
}

export function* uiApplyDateFilter({ payload: { dateFrom, dateTo, dateRange } }) {
  yield put(NewsActions.toggleDateForm(false))
  yield put(FilterActions.changeDate({ dateFrom, dateTo, dateRange }))
  yield put(NewsActions.newsRequestStart())
}

export function* uiChooseQuickSelect({ payload: range }) {
  const { from, to } = yield call(rangeToDates, range)

  if (from || to) {
    yield put(Actions.uiApplyDateFilter({
      dateFrom: from,
      dateTo: to,
      dateRange: range
    }))
  }
}

export function* uiApplyFilters() {
  yield put(NewsActions.toggleAdvancedSearch())
  yield put(NewsActions.newsRequestStart())
}

export function* uiResetFilters({ payload: toggleForm }) {
  const globalClusterDefault = yield select(Selectors.getGlobalClusterDefault)
  yield put(FilterActions.resetFilters(globalClusterDefault))

  if (toggleForm === undefined || toggleForm) {
    yield put(NewsActions.toggleAdvancedSearch())
  }

  yield put(NewsActions.newsRequestStart())
}

export function* uiResetFilterFields({ payload }) {
  yield put(FilterActions.resetFilterFields(payload))
  yield put(NewsActions.newsRequestStart())
}

export function* toggleFilterForm() {
  const device = yield select(Selectors.getDevice)

  if (device.get('lt-lg')) {
    yield call(scrollToTop)
  }
}

export function* uiSetBoolean(action) {
  yield put(FilterActions.setBoolean(action.payload))
  yield put(NewsActions.newsRequestStart())
}

export function* uiUpdateFilter(action) {
  yield put(FilterActions.updateFilter(action.payload))
  yield put(NewsActions.newsRequestStart())
}

export function* uiSetAnalysisFilter(action) {
  yield put(FilterActions.setAnalysisFilter(action.payload))
  yield put(NewsActions.newsRequestStart())
}

export function* uiSwapAnalysisCodeSelectionAndGrouping() {
  yield put(FilterActions.swapAnalysisCodeSelectionAndGrouping())
  yield put(NewsActions.newsRequestStart())
}

export function* watchUiSetSortBy() {
  yield takeEvery(Actions.uiSetSortBy, uiSetSortBy)
}

export function* watchUiSetSortDirection() {
  yield takeEvery(Actions.uiSetSortDirection, uiSetSortDirection)
}

export function* watchUiEnteredQuery() {
  yield takeEvery(Actions.uiEnteredQuery, uiEnteredQuery)
}

export function* watchUiRemoveFilter() {
  yield takeEvery(Actions.uiRemoveFilter, uiRemoveFilter)
}

export function* watchUiInvertFilter() {
  yield takeEvery(Actions.uiInvertFilter, uiInvertFilter)
}

export function* watchUiAddFilters() {
  yield takeEvery(Actions.uiAddFilters, uiAddFilters)
}

export function* watchUiReplaceFilters() {
  yield takeEvery(Actions.uiReplaceFilters, uiReplaceFilters)
}

export function* watchUiApplyDateFilter() {
  yield takeEvery(Actions.uiApplyDateFilter, uiApplyDateFilter)
}

export function* watchUiChooseQuickSelect() {
  yield takeEvery(Actions.uiChooseQuickSelect, uiChooseQuickSelect)
}

export function* watchUiApplyFilters() {
  yield takeEvery(Actions.uiApplyFilters, uiApplyFilters)
}

export function* watchUiResetFilters() {
  yield takeEvery(Actions.uiResetFilters, uiResetFilters)
}

export function* watchUiResetFilterFields() {
  yield takeEvery(Actions.uiResetFilterFields, uiResetFilterFields)
}

export function* watchToggleFilterForm() {
  yield takeEvery(NewsActions.toggleAdvancedSearch, toggleFilterForm)
  yield takeEvery(NewsActions.toggleDateForm, toggleFilterForm)
}

export function* watchUiSetBoolean() {
  yield takeEvery(Actions.uiSetBoolean, uiSetBoolean)
}

export function* watchUiUpdateFilter() {
  yield takeEvery(Actions.uiUpdateFilter, uiUpdateFilter)
}

export function* watchUiSetAnalysisFilter() {
  yield takeEvery(Actions.uiSetAnalysisFilter, uiSetAnalysisFilter)
}

export function* watchUiSwapAnalysisCodeSelectionAndGrouping() {
  yield takeEvery(Actions.uiSwapAnalysisCodeSelectionAndGrouping, uiSwapAnalysisCodeSelectionAndGrouping)
}

export default function* appSaga() {
  yield all([
    watchUiSetSortBy(),
    watchUiSetSortDirection(),
    watchUiEnteredQuery(),
    watchUiRemoveFilter(),
    watchUiInvertFilter(),
    watchUiAddFilters(),
    watchUiReplaceFilters(),
    watchUiApplyDateFilter(),
    watchUiChooseQuickSelect(),
    watchUiApplyFilters(),
    watchUiResetFilters(),
    watchUiResetFilterFields(),
    watchToggleFilterForm(),
    watchUiSetBoolean(),
    watchUiUpdateFilter(),
    watchUiSetAnalysisFilter(),
    watchUiSwapAnalysisCodeSelectionAndGrouping()
  ])
}
