import { createAction } from 'redux-actions'

const prefix = 'NEWS/FILTER/'

export const addNewsQuery = createAction(`${prefix}ADD_NEWS_QUERY`)
export const addNewsQueries = createAction(`${prefix}ADD_NEWS_QUERIES`)
export const addFilters = createAction(`${prefix}ADD_FILTERS`)
export const replaceFilters = createAction(`${prefix}REPLACE_FILTERS`)
export const resetFilterFields = createAction(`${prefix}RESET_FILTER_FIELDS`)
export const changeDate = createAction(`${prefix}CHANGE_DATE`)
export const changeDateInterval = createAction(`${prefix}CHANGE_DATE_INTERVAL`)
export const changeDateType = createAction(`${prefix}CHANGE_DATE_TYPE`)
export const removeFilter = createAction(`${prefix}REMOVE_FILTER`)
export const resetFilters = createAction(`${prefix}RESET_FILTERS`)
export const setFilter = createAction(`${prefix}SET_FILTER`)
export const setBoolean = createAction(`${prefix}SET_BOOLEAN`)
export const setSelectedQueryType = createAction(`${prefix}SET_SELECTED_QUERY_TYPE`)
export const setSelectedFilters = createAction(`${prefix}SET_SELECTED_FILTERS`)
export const invertFilter = createAction(`${prefix}INVERT_FILTER`)
export const updateFilter = createAction(`${prefix}UPDATE_FILTER`)
export const setAnalysisFilter = createAction(`${prefix}SET_ANALYSIS_FILTER`)
export const setSimilarityFilter = createAction(`${prefix}SET_SIMILARITY_FILTER`)
export const resetAnalysisCodesFilter = createAction(`${prefix}RESET_ANALYSIS_CODES_FILTER`)
export const toggleStatementTonality = createAction(`${prefix}TOGGLE_STATEMENT_TONALITY`)
export const swapAnalysisCodeSelectionAndGrouping = createAction(`${prefix}SWAP_ANALYSIS_CODE_SELECTION_AND_GROUPING`)
