import { scaleLinear } from 'd3-scale'

import { NewsPageModules } from 'static/constants'
import store from '../store'

const getStaticTonalities = state => state.statics.getIn(['sorted', 'tonalities'])
const getStatementEvaluationSteps = state => state.config.get('statementEvaluationSteps')
const getEvaluationSteps = state => state.config.get('evaluationSteps')

let savedDomain
let savedRange
let savedUnknownColor

const getDomainAndRange = () => {
  if (savedDomain && savedRange) {
    return [savedDomain, savedRange]
  }

  const tonalities = getStaticTonalities(store.getState()).filter(t => t.get('value') !== 1000)

  savedDomain = tonalities.map(t => t.get('value')).toJS()
  savedRange = tonalities.map(t => t.get('color')).toJS()

  return [savedDomain, savedRange]
}

const getUnknownColor = () => {
  if (savedUnknownColor) {
    return savedUnknownColor
  }

  savedUnknownColor = getStaticTonalities(store.getState()).find(t => t.get('value') === 1000).get('color')

  return savedUnknownColor
}

export const getTonalityColor = value => {
  if (value === null) {
    return getUnknownColor()
  }

  const [domain, range] = getDomainAndRange()

  return scaleLinear().domain(domain).range(range)(value)
}

export const getAverageTonalityOfNews = news => {
  const tonalitySum = news
    .get('clusteredNews')
    .map(n => n.get('tonality'))
    .filter(t => t !== null && t !== undefined && t !== 1000)
    .reduce((acc, t) => acc + t, 0)

  return tonalitySum / news.get('clusteredNews').size
}

export const getAverageTonalityColorOfNews = news => getTonalityColor(getAverageTonalityOfNews(news))

export const getTonalititesByModule = module => {
  let scale = 3

  if (module === NewsPageModules.ANALYSIS) {
    scale = getStatementEvaluationSteps(store.getState()) || getEvaluationSteps(store.getState())
  } else {
    scale = getEvaluationSteps(store.getState())
  }

  scale = scale || 3

  const tonalities = getStaticTonalities(store.getState()).filter(t => t.get('value') !== 1000)

  const max = (scale - 1) / 2
  const min = max * -1

  return tonalities.filter(t => t.get('value') >= min && t.get('value') <= max)
}

export const isTonalityDimension = dimension => `${dimension}`.includes('onalit')
