import { call, put, select, takeEvery, all, delay, debounce } from 'redux-saga/effects'
import decamelizeKeysDeep from 'decamelize-keys-deep'
import decamelize from 'decamelize'
import { fromJS, List } from 'immutable'
import moment from 'moment-timezone'

import { NewsPageModules, ExcelMimeType } from 'static/constants'

import {
  generateChartLabel,
  isTimeline,
  isPressrelationsNewsChart,
  isImageChart,
  isTextChart,
  isExternalWidgetChart,
  isFeedChart,
  aiAnalysisId
} from 'utils/chart'
import * as Actions from 'actions/charts'
import * as AppActions from 'actions/app'
import * as UiActions from 'actions/ui'
import * as FilterActions from 'actions/filter'
import * as NewsActions from 'actions/news'
import * as Api from 'api/bff'
import * as Selectors from 'selectors'
import { downloadCanvas } from 'utils/downloadSvg'
import { rangeToDates } from 'utils/date'
import { retriable, downloadBlob, uploadFile } from 'utils/sagas'

const powerpointMimeType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'

// const legendSelectors = {
//   line: '.recharts-legend-wrapper>ul',
//   area: '.recharts-legend-wrapper>ul',
//   bar: '.recharts-legend-wrapper>ul',
//   horizontalBar: '.recharts-legend-wrapper>ul',
//   pie: '.recharts-legend-wrapper>ul'
// }
// const domToImageCharts = ['bubble', 'worldMap']

const allowedBreakpoints = ['xs', 'sm', 'md', 'lg']

const defaultFirstLevelChartsForNews = 'mediaReviewCodes'

export const generateFilename = (chart, i18n, ext) => {
  const result = `${chart.get('label') || chart.get('topLabel') || generateChartLabel(chart, i18n)}.${ext}`

  return result.replace(/(\s|\/)+/g, '_').replace(/_+/g, '_').toLowerCase()
}

export function* fetchChartDataTry({ chart, searchBody }) {
  if (!isImageChart(chart) && !isTextChart(chart) && !isExternalWidgetChart(chart)) {
    const body = yield select(Selectors.getChartBody, chart, searchBody)

    return yield call(Api.fetchChartData, body)
  }

  return null
}

export function* fetchChartDataFail(error) {
  yield put(AppActions.exception(error))
}

export function* fetchChartData(chart, searchBody) {
  return yield call(retriable, fetchChartDataTry, fetchChartDataFail, {
    chart,
    searchBody
  })
}

export function* updateCharts() {
  try {
    const charts = yield select(Selectors.getRawCharts)
    const layouts = yield select(Selectors.getChartsLayouts)

    const sanitizedCharts = charts.reduce(
      (acc, values, key) => acc.set(key, values.map(chart => chart.delete('data'))),
      fromJS({})
    ).toJS()

    const body = {
      charts: decamelizeKeysDeep(sanitizedCharts),
      layouts: layouts.toJS()
    }

    yield call(Api.updateCharts, body)
    yield put(Actions.updateChartsSuccess({ charts, layouts }))
  } catch (error) {
    yield put(Actions.updateChartsError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* updateChartsSuccess() {
  const charts = {}
  const layouts = {}

  yield put(Actions.setPrevious({ charts, layouts }))
  yield put(Actions.setNormalMode())
}

export function* getChartExportBody(chart, searchBody, size, ext) {
  const i18n = yield select(Selectors.getI18n)
  const exportSize = yield call(Selectors.getChartBodySizeForExport, chart, ext)
  let body = yield select(Selectors.getChartBody, chart, searchBody, size || exportSize)

  body = {
    ...body,
    sheet_name: i18n.get(decamelize(chart.get('firstLevel') || chart.get('type')))
  }

  return body
}

export function* chartExport(chart, searchBody, apiFunc, ext, mimeType, size) {
  const i18n = yield select(Selectors.getI18n)
  const body = yield* getChartExportBody(chart, searchBody, size, ext)
  const filename = generateFilename(chart, i18n, ext)
  const result = yield call(apiFunc, body)

  yield call(downloadBlob, result, filename, mimeType)
}

// Used in other sagas
export function* excelExport(chart, searchBody) {
  try {
    yield* chartExport(chart, searchBody, Api.exportExcel, 'xlsx', ExcelMimeType)

    yield put(Actions.exportExcelSuccess())
  } catch (error) {
    yield put(Actions.exportExcelError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* exportExcel({ payload: chart }) {
  const searchBody = yield select(Selectors.getSearchBody)
  yield* excelExport(chart, searchBody)
}

// Used in other sagas
export function* powerpointExport(chart, searchBody) {
  try {
    yield* chartExport(chart, searchBody, Api.exportPowerpoint, 'pptx', powerpointMimeType, chart.getIn(['opts', 'size'], 10))

    yield put(Actions.exportPowerpointSuccess())
  } catch (error) {
    yield put(Actions.exportPowerpointError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* exportPowerpoint({ payload: chart }) {
  const searchBody = yield select(Selectors.getSearchBody)
  yield* powerpointExport(chart, searchBody)
}

// Used in other sagas
export function* powerpointBatchExport(charts, searchBody, opts = {}) {
  try {
    const chartBodies = []
    charts.forEach(chart => chartBodies.push(call(getChartExportBody, chart, searchBody, chart.getIn(['opts', 'size'], 10), 'pptx')))

    let body = {
      charts: yield all(chartBodies)
    }

    body = { ...body, ...opts }

    const result = yield call(Api.exportPowerpointBatch, body)

    yield call(downloadBlob, result, opts.filename || 'charts.pptx', powerpointMimeType)

    yield put(Actions.exportPowerpointBatchSuccess())
  } catch (error) {
    yield put(Actions.exportPowerpointBatchError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* powerpointBatchExportStart({ payload }) {
  const breakpoint = yield select(Selectors.getBreakpoint)
  let charts = yield select(Selectors.getChartsCharts)
  const searchBody = yield select(Selectors.getSearchBody)
  const timezone = yield select(Selectors.getTimezone)
  const logo = yield select(Selectors.getLogo)

  charts = charts
    .get(breakpoint)
    .map(chart => {
      let newChart = chart.delete('data')

      if (payload && payload.aiEnabled) {
        newChart = newChart.set('aiEnabled', true)
      }

      return newChart
    })

  yield call(powerpointBatchExport, charts, searchBody, {
    timezone,
    logo
  })

  yield put(Actions.exportPowerpointBatchSuccess())
}

export function* downloadImage({ payload: { chart, id } }) {
  try {
    const i18n = yield select(Selectors.getI18n)
    const filename = generateFilename(chart, i18n, 'png')

    const result = yield call(downloadCanvas, id)

    yield call(downloadBlob, result.blob, filename, 'image/png')
  } catch (error) {
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
  }
}

export function* setPrevious() {
  const isEditing = yield select(Selectors.getChartsIsEditing)
  let charts = yield select(Selectors.getChartsCharts)
  let layouts = yield select(Selectors.getChartsLayouts)

  if (!isEditing) {
    charts = yield select(Selectors.getChartsPreviousCharts)
    layouts = yield select(Selectors.getChartsPreviousLayouts)
    yield put(Actions.setPreviousToData({ charts, layouts }))

    charts = {}
    layouts = {}
  }

  yield put(Actions.setPrevious({ charts, layouts }))
}

export function* drilldown({ payload: { chart, d: { filterData }, secondFilter, replace } }) {
  let secondLevelField = chart.get('secondLevel')

  if (secondLevelField === 'tonality') {
    secondLevelField = 'tonalities'
  }

  let firstLevelField = chart.get('firstLevel')

  if (firstLevelField === 'tonality') {
    firstLevelField = 'tonalities'
  }

  if (secondLevelField === 'sentiment') {
    secondLevelField = 'sentiments'
  }

  if (!isTimeline(chart)) {
    if (chart.get('dateRange')) {
      const { from: dateFrom, to: dateTo } = yield call(rangeToDates, chart.get('dateRange'))

      yield put(FilterActions.changeDate({
        dateFrom,
        dateTo
      }))
    } else if (chart.get('dateFrom') || chart.get('dateTo')) {
      const dateFrom = chart.get('dateFrom') ? new Date(chart.get('dateFrom')) : null
      const dateTo = chart.get('dateTo') ? new Date(chart.get('dateTo')) : null

      yield put(FilterActions.changeDate({
        dateFrom,
        dateTo
      }))
    }

    const filters = []

    if (firstLevelField === 'analysisCodes') {
      yield put(FilterActions.setAnalysisFilter({
        field: 'selectedCodes',
        value: fromJS([filterData])
      }))
    } else {
      filters.push({
        filter: fromJS(filterData),
        field: firstLevelField
      })
    }

    if (secondFilter) {
      if (secondLevelField === 'analysisCodes') {
        yield put(FilterActions.setAnalysisFilter({
          field: 'groupedCodes',
          value: fromJS([secondFilter])
        }))
      } else if (secondLevelField === 'statementTonalities') {
        yield put(FilterActions.setAnalysisFilter({
          field: 'statementTonalities',
          value: fromJS([secondFilter])
        }))
      } else {
        filters.push({
          filter: fromJS(secondFilter),
          field: secondLevelField
        })
      }
    }

    if (filters.length) {
      if (replace) {
        yield put(UiActions.uiReplaceFilters(filters))
      } else {
        yield put(UiActions.uiAddFilters(filters))
      }
    } else {
      yield put(NewsActions.newsRequestStart())
    }
  } else if (secondFilter) {
    yield put(FilterActions.changeDate({
      dateFrom: new Date(filterData.range.from),
      dateTo: new Date(filterData.range.to)
    }))

    if (secondLevelField === 'analysisCodes') {
      yield put(UiActions.uiSetAnalysisFilter({
        field: 'groupedCodes',
        value: fromJS([secondFilter])
      }))
    } else if (secondLevelField === 'statementTonalities') {
      yield put(UiActions.uiSetAnalysisFilter({
        field: 'statementTonalities',
        value: fromJS([secondFilter])
      }))
    } else if (replace) {
      yield put(UiActions.uiReplaceFilters([{
        filter: fromJS(secondFilter),
        field: secondLevelField
      }]))
    } else {
      yield put(UiActions.uiAddFilters([{
        filter: fromJS(secondFilter),
        field: secondLevelField
      }]))
    }
  } else {
    yield put(UiActions.uiApplyDateFilter({
      dateFrom: new Date(filterData.range.from),
      dateTo: new Date(filterData.range.to)
    }))
  }
}

function* calculatePressrelationsNewsChart(pChart, key, value) {
  let chart = pChart

  if (key === 'firstLevel') {
    if (value === '') {
      chart = chart.set(key, defaultFirstLevelChartsForNews)
    } else {
      chart = chart.set(key, value)
    }

    const chartTypes = yield select(Selectors.getChartDialogChartTypes, chart)

    if (!chartTypes.includes(chart.get('type'))) {
      chart = chart.set('type', chartTypes.first())
    }

    const thirdLevels = yield select(Selectors.getChartDialogThirdLevels, chart)

    if (value === 'pageIdentitiesTimeline') {
      chart = chart.set('secondLevel', 'pageIdentities')

      if (!thirdLevels.includes(chart.get('thirdLevel'))) {
        chart = chart.set('thirdLevel', thirdLevels.first())
      }
    } else if (value === 'savedSearchesTimeline') {
      chart = chart.set('secondLevel', 'savedSearches')

      if (!thirdLevels.includes(chart.get('thirdLevel'))) {
        chart = chart.set('thirdLevel', thirdLevels.first())
      }
    } else {
      const secondLevels = yield select(Selectors.getChartDialogSecondLevels, chart)

      if (!secondLevels.includes(chart.get('secondLevel'))) {
        chart = chart.delete('secondLevel')
      }
    }

    if (!thirdLevels.includes(chart.get('thirdLevel'))) {
      chart = chart.delete('thirdLevel')
    }
  } else if (key === 'secondLevel') {
    if (chart.get(key) === value) {
      chart = chart.delete(key)
    } else {
      chart = chart.set(key, value)
    }
  } else if (key === 'thirdLevel') {
    chart = chart.set(key, value)
  } else if (key === 'type') {
    chart = chart.set(key, value)

    const secondLevels = yield select(Selectors.getChartDialogSecondLevels, chart)

    if (!secondLevels.includes(chart.get('secondLevel'))) {
      chart = chart.delete('secondLevel')
    }

    const thirdLevels = yield select(Selectors.getChartDialogThirdLevels, chart)

    if (!thirdLevels.includes(chart.get('thirdLevel'))) {
      chart = chart.delete('thirdLevel')
    }
  } else {
    chart = chart.set(key, value)
  }

  return chart
}

export function* calculateNewChart({ payload: { chart: pChart, key, value: v, onChange, afterChange } }) {
  const value = v || ''
  let chart

  if (isPressrelationsNewsChart(pChart)) {
    chart = yield* calculatePressrelationsNewsChart(pChart, key, value)
  } else {
    chart = pChart.set(key, value)

    if (isImageChart(chart)) {
      chart = chart.set('type', 'image')
    }

    if (isTextChart(chart)) {
      chart = chart.set('type', 'text')
    }

    if (isExternalWidgetChart(chart)) {
      chart = chart.set('type', 'externalWidget')
    }
  }

  onChange(chart.delete('data'))

  if (afterChange) {
    afterChange({
      chartBefore: pChart
    })
  }

  return chart
}

export function* fetchChartAggregation(chart, searchBody, index, breakpoint) {
  try {
    const viewConfig = yield select(Selectors.getNewsViewConfig)

    yield put(Actions.setChartLoading({
      index,
      breakpoint
    }))
    const useAggregationValue = yield select(Selectors.getViewConfigUseAggregationValue)
    let newChart = chart

    if (useAggregationValue) {
      const aggregationValue = yield select(Selectors.getNewsAggregationsAggregationValue)
      newChart = newChart.set('thirdLevel', aggregationValue)
    }

    const result = yield call(fetchChartData, newChart, searchBody)

    const chartData = {
      index,
      breakpoint,
      data: result ? result[chart.get('firstLevel')] : null
    }

    if (viewConfig.get('moduleName') === NewsPageModules.NEWS_POOL) {
      yield put(Actions.setStaticChartData(chartData))
    } else {
      yield put(Actions.setChartData(chartData))
    }
  } catch (error) {
    yield put(Actions.setChartData({
      index,
      breakpoint,
      data: null
    }))
  }
}

export function* fetchAggregations() {
  const viewConfig = yield select(Selectors.getNewsViewConfig)
  let charts

  if (viewConfig.get('moduleName') === 'news_pool') {
    charts = yield select(Selectors.getStaticChartsCharts)
  } else {
    charts = yield select(Selectors.getChartsCharts)
  }

  const searchBody = yield select(Selectors.getSearchBody)

  const currentBreakpoint = yield select(Selectors.getBreakpoint)
  const device = yield select(Selectors.getDevice)

  let breakpointsToLoad = allowedBreakpoints

  if (device.get('phone') || device.get('tablet')) {
    breakpointsToLoad = [currentBreakpoint]

    if (device.get('landscape')) {
      breakpointsToLoad.push(allowedBreakpoints[allowedBreakpoints.indexOf(currentBreakpoint) - 1])
    } else {
      breakpointsToLoad.push(allowedBreakpoints[allowedBreakpoints.indexOf(currentBreakpoint) + 1])
    }
  } else if (viewConfig.get('moduleName') === 'news_pool') {
    breakpointsToLoad = [currentBreakpoint]
  }

  const preferredEffects = []
  const effects = []

  charts.forEach((cs, breakpoint) => {
    cs.forEach((chart, index) => {
      if (breakpointsToLoad.indexOf(breakpoint) !== -1) {
        // Prefer current breakpoint to fake faster loading
        if (breakpoint === currentBreakpoint) {
          preferredEffects.push(call(fetchChartAggregation, chart, searchBody, index, breakpoint))
        } else {
          effects.push(call(fetchChartAggregation, chart, searchBody, index, breakpoint))
        }
      }
    })
  })

  yield all(preferredEffects)

  yield put(Actions.aggregationsRequestSuccess())

  yield delay(1000)
  yield all(effects)

  return null
}

export function* setChart({ payload }) {
  const { chart, index, breakpoint } = payload
  const searchBody = yield select(Selectors.getSearchBody)
  yield put(Actions.setChart({
    index,
    breakpoint,
    chart: chart.delete('data')
  }))
  // Handle the case where the chart is new so we do not have an index yet
  let j = index

  if (j === null) {
    let charts
    const viewConfig = yield select(Selectors.getNewsViewConfig)

    if (viewConfig.get('moduleName') === 'news_pool') {
      charts = yield select(Selectors.getStaticChartsCharts)
    } else {
      charts = yield select(Selectors.getChartsCharts)
    }

    j = charts.get(breakpoint).size - 1
  }

  yield call(fetchChartAggregation, chart, searchBody, j, breakpoint)
}

export function* setAggregationValue() {
  yield put(Actions.aggregationsRequestStart())
}

export function* loadSelectedChartAggregations() {
  try {
    yield put(Actions.setSelectedChartLoading(true))
    const chart = yield select(Selectors.getChartsSelectedChart)
    const searchBody = yield select(Selectors.getSearchBody)

    const result = yield call(fetchChartData, chart, searchBody)

    if (result) {
      yield put(Actions.setSelectedChartData(result[chart.get('firstLevel')]))
    }

    yield put(Actions.setSelectedChartLoading(false))
  } catch (error) {
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* checkChartChange({ payload: { chartBefore } }) {
  const chartAfter = yield select(Selectors.getChartsSelectedChart)
  const reload = ['firstLevel', 'secondLevel', 'thirdLevel', 'type'].some(level => chartBefore.get(level) !== chartAfter.get(level))

  if (reload) {
    yield call(loadSelectedChartAggregations)
  }
}

export function* setSelectedChartOpt({ payload: { key } }) {
  const fields = [
    'continentCode',
    'size',
    'aggregationExcludeBuckets',
    'usePercentAsValue',
    'replaceSnippetsWithSummaries',
    'allowedEntityTypes'
  ]

  if (fields.indexOf(key) !== -1) {
    yield call(loadSelectedChartAggregations)
  }
}

export function* aiChartAnalysis() {
  try {
    let url
    const i18n = yield select(Selectors.getI18n)
    const chart = yield select(Selectors.getChartsAiChartAnalysisChart)
    const locale = yield select(Selectors.getAiSettingsLocale)

    if (!List.isList(chart.get('data')) && !isFeedChart(chart)) {
      yield delay(500)

      const filename = generateFilename(chart, i18n, 'png')
      const imageResult = yield call(downloadCanvas, aiAnalysisId, null, { scale: 1 })
      const keepUntil = moment().add(1, 'hours').format()
      const fileResult = yield call(uploadFile, imageResult.blob, filename, 'image/png', keepUntil)

      url = fileResult.url
    }

    const serializedChart = JSON.stringify(chart.toJS())
    const result = yield call(Api.aiChartAnalysis, { url, chart: serializedChart, locale })

    yield put(Actions.aiChartAnalysisSuccess(result))
  } catch (error) {
    yield put(AppActions.exception(error))
    yield put(AppActions.genericErrorMessage())
    yield put(Actions.aiChartAnalysisError(error))
  }
}

export function* aiChartDescription({ payload: { breakpoint, index, chart } }) {
  try {
    yield put(Actions.setChart({
      index,
      breakpoint,
      chart: chart.set('aiDescriptionLoading', true)
    }))

    const { description } = yield call(Api.aiChartDescription, { chart: JSON.stringify(chart.toJS()) })

    yield put(Actions.setChart({
      index,
      breakpoint,
      chart: chart.merge({
        aiDescription: description,
        aiDescriptionLoading: false
      })
    }))

    yield put(Actions.aiChartDescriptionSuccess())
  } catch (error) {
    const i18n = yield select(Selectors.getI18n)

    yield put(Actions.setChart({
      index,
      breakpoint,
      chart: chart.merge({
        aiDescriptionLoading: false,
        aiDescription: i18n.get('unknown_error')
      })
    }))

    yield put(Actions.aiChartDescriptionError(error))
    yield put(AppActions.genericErrorMessage())
    yield put(AppActions.exception(error))
  }
}

export function* watchUpdateCharts() {
  yield takeEvery(Actions.updateChartsStart, updateCharts)
}

export function* watchUpdateChartsSuccess() {
  yield takeEvery(Actions.updateChartsSuccess, updateChartsSuccess)
}

export function* watchExportExcel() {
  yield takeEvery(Actions.exportExcelStart, exportExcel)
}

export function* watchExportPowerpoint() {
  yield takeEvery(Actions.exportPowerpointStart, exportPowerpoint)
}

export function* watchExportPowerpointBatch() {
  yield takeEvery(Actions.exportPowerpointBatchStart, powerpointBatchExportStart)
}

export function* watchDownloadImage() {
  yield takeEvery(Actions.downloadImage, downloadImage)
}

export function* watchToggleEditing() {
  yield takeEvery(Actions.toggleEditing, setPrevious)
}

export function* watchDrilldown() {
  yield takeEvery(Actions.drilldown, drilldown)
}

export function* watchCalculateNewChart() {
  yield takeEvery(Actions.calculateNewChart, calculateNewChart)
}

export function* watchFetchAggregations() {
  yield takeEvery(Actions.aggregationsRequestStart, fetchAggregations)
}

export function* watchSetAggregationValue() {
  yield takeEvery(Actions.setAggregationValue, setAggregationValue)
}

export function* watchShowChartDialog() {
  yield takeEvery(Actions.showChartDialog, loadSelectedChartAggregations)
}

export function* watchSetChart() {
  yield takeEvery(Actions.setChartStart, setChart)
}

export function* watchCheckChartChange() {
  yield takeEvery(Actions.checkChartChange, checkChartChange)
}

export function* watchSetSelectedChartOpt() {
  yield debounce(1000, Actions.setSelectedChartOpt, setSelectedChartOpt)
}

export function* watchAiChartAnalysis() {
  yield takeEvery(Actions.aiChartAnalysisStart, aiChartAnalysis)
}

export function* watchAiChartDescription() {
  yield takeEvery(Actions.aiChartDescriptionStart, aiChartDescription)
}

export default function* saga() {
  yield all([
    watchUpdateCharts(),
    watchUpdateChartsSuccess(),
    watchExportExcel(),
    watchExportPowerpoint(),
    watchExportPowerpointBatch(),
    watchDownloadImage(),
    watchToggleEditing(),
    watchDrilldown(),
    watchCalculateNewChart(),
    watchFetchAggregations(),
    watchShowChartDialog(),
    watchSetChart(),
    watchSetAggregationValue(),
    watchCheckChartChange(),
    watchSetSelectedChartOpt(),
    watchAiChartAnalysis(),
    watchAiChartDescription()
  ])
}
