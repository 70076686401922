import { fromJS } from 'immutable'
import { createImmutableSelector } from 'utils/reselect'
import camelcase from 'camelcase'

import store from '../store'

const getKeyMap = createImmutableSelector(
  state => state.statics.getIn(['sorted', 'aiEntities']) || fromJS([]),
  aiEntities => (aiEntities || fromJS([])).reduce((acc, c) => acc.merge({
    [c.get('key')]: c,
    [camelcase(c.get('key'))]: c
  }), fromJS({}))
)

export const isAiEntity = key => !!key && getKeyMap(store.getState()).has(key)

export const isAiEntityKey = key => camelcase(`${key}`).startsWith('aiEntityInstance')

export const getAiEntityName = key => getKeyMap(store.getState()).getIn([key, 'label'])
