/* global document, window */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'
import { createRoot } from 'react-dom/client'

import { createLoadable } from 'utils/loadable'
import { isEmbeddedChartView } from 'utils/embedded_charts'
import { checkViewVersion } from 'actions/app'
import { changeWidthAndHeight } from 'actions/environment'

import { checkIp, enableRecording, disableRecording } from 'utils/tracking'
import store from './store'

import 'moment/locale/de'
import 'moment/locale/fr'
import 'moment/locale/en-gb' // default language if no configs are fetched
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-pro/css/all.css'
import '@fortawesome/fontawesome-pro/css/v4-shims.css'
import 'flag-icons/css/flag-icons.min.css'
import 'typeface-roboto/index.css'
import 'stylesheets/react-grid-layout.css'

const Root = createLoadable(() => import(/* webpackChunkName: "Root" */ 'containers/Root'))
const RootEmbed = createLoadable(() => import(/* webpackChunkName: "RootEmbed" */ 'containers/RootEmbed'))

store.dispatch(checkViewVersion())

window.onresize = () => store.dispatch(changeWidthAndHeight({
  width: window.innerWidth,
  height: window.innerHeight
}))

global.mouseX = 0
global.mouseY = 0

window.onmousemove = e => {
  try {
    global.mouseX = e.clientX
    global.mouseY = e.clientY
  } catch (e) { // eslint-disable-line
  }
}

if (Object.prototype.hasOwnProperty.call(window, 'UST')) {
  // eslint-disable-next-line
  window.UST.onTrackingStarted = function() {
    console.log('Wizard started') // eslint-disable-line
    window.UST.currentIP = window.ust_myIP
    window.UST.trackingAllowed = checkIp(window.UST.currentIP)
    window.UST.trackingAllowed ? enableRecording() : disableRecording() // eslint-disable-line
  }
}

const allowedDomains = [
  'http://www.scompler.com',
  'https://www.scompler.com',
  'http://scompler.com',
  'https://scompler.com',
  'http://test.pressrelations.de',
  'https://test.pressrelations.de',
  'http://customers.pressrelations.de',
  'https://customers.pressrelations.de'
]

window.addEventListener('message', evt => {
  if (allowedDomains.indexOf(evt.origin) !== -1) {
    if (evt.data.type === 'injectStyle') {
      const styleEl = document.createElement('style')
      styleEl.setAttribute('type', 'text/css')

      if (styleEl.styleSheet) {
        styleEl.styleSheet.cssText = evt.data.data
      } else {
        styleEl.appendChild(document.createTextNode(evt.data.data))
      }

      document.head.appendChild(styleEl)

      console.info(`Processed message ${evt.data.type} from ${evt.origin}`) // eslint-disable-line
    }
  }
})

const container = document.getElementById('root')
const root = createRoot(container)

if (isEmbeddedChartView()) {
  root.render(<RootEmbed store={store} />)
} else {
  root.render(<Root store={store} />)
}

if (module.hot) {
  module.hot.accept()
}
