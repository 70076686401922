import { handleActions, combineActions } from 'redux-actions'
import { fromJS } from 'immutable'

import * as AppActions from 'actions/app'
import * as NewsActions from 'actions/news'
import * as ExportActions from 'actions/export'
import * as Actions from 'actions/ai'

export const initialState = fromJS({
  showDialog: false,
  loading: false,
  analysis: null
})

export default handleActions({
  [combineActions(
    Actions.showAiAnalysisDialog,
    NewsActions.showAiAnalysisDialog
  )]: state => state.merge({
    showDialog: true,
    analysis: null
  }),
  [Actions.hideAiAnalysisDialog]: state => state.merge({
    showDialog: false,
    analysis: null
  }),

  [Actions.aiAnalysisStart]: state => state.set('loading', true),
  [Actions.aiAnalysisSuccess]: (state, { payload: { analysis } }) => state.merge({
    loading: false,
    analysis
  }),
  [Actions.aiAnalysisError]: state => state.set('loading', false),

  [Actions.setAiAnalysis]: (state, { payload: analysis }) => state.set('analysis', analysis),

  [ExportActions.showExportDialog]: state => state.set('analysis', null),

  [AppActions.resetState]: () => initialState
}, initialState)
