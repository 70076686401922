import decamelizeKeysDeep from 'decamelize-keys-deep'
import { fromJS, Map, List } from 'immutable'
import { NewsPageSize, ChartDataSources, ChartTypes, Capabilities, SortingOptions } from 'static/constants'
import moment from 'moment-timezone'
import { createImmutableSelector } from 'utils/reselect'
import decamelize from 'decamelize'

import {
  isPressrelationsNewsChart,
  isGoogleAnalyticsChart,
  isMozChart,
  isYoutubeAnalyticsChart,
  isLinkedInAnalyticsChart,
  isFacebookAnalyticsChart,
  isTwitterAnalyticsChart,
  isImageChart,
  isTextChart,
  isExternalWidgetChart,
  generateChartLabel,
  generateChartTopLabel
} from 'utils/chart'

import {
  getSelectedNewsFilters,
  getSelectedPageIdentityFilters,
  getSelectedPublicationFilters,
  getSelectedOutlinkFilters,
  getSelectedAuthorFilters,
  getSelectedDateType,
  getSelectedDateInterval,
  getActiveFilters,
  getNewsFiltersAnalysisSelectedCodeIdsForExport,
  getNewsFiltersAnalysisGroupedCodeIdsForExport,
  getNewsFiltersAnalysisRestrictedCodeIds,
  getNewsFiltersAnalysisStatementTonalityValues,
  getNewsFiltersAnalysisArticleLevel,
  getSelectedDateFrom,
  getSelectedDateTo,
  getNewsFiltersAnalysisUseMediaReviewCodes
} from './filters'
import { getTimezone } from './app'
import {
  getShoppingCartCodesSize,
  getShoppingCartTabIndex,
  getShoppingCartCodes,
  getShoppingCartNewsIds,
  getShoppingCartNews,
  getShoppingCartSelectedNewsIds,
  getShoppingCartSelectedNews,
  getShoppingCartIsStructuredExport
} from './news/shopping_cart'
import { getConfig, getExportLimit, getXlsxExportLimit, getBackwardsNewsXlsxLimit } from './config'
import { getAllSelectedTopicIds, getSelectedEditionIds } from './subscriptions'
import { getCapabilities, getUser, getCookieOption } from './user'
import {
  getExportOptions,
  getExportSettings,
  getCurrentDocumentUrl,
  getCurrentDocumentFilename,
  getCurrentDocumentMimeType,
  getCurrentDocumentSize,
  getCurrentDocumentAttachments,
  getLastExport
} from './news/export'
import { getPagingByGroupId, getSortBy, getSortDirection, getNewsGroups, getNewsGroupingType } from './news'
import {
  getCurrentPath,
  isShoppingCart,
  isMediaReviewDetail,
  isNewsBoard,
  isDashboard,
  isAdministrationSubmodule,
  isContentDeskSubmodule,
  isContentDeskContentsAndCampaigns,
  isContactManagementSubmodule,
  isDarknetQueryManager,
  isSearchPool,
  isInfoboard,
  isExternalChart,
  isSavedChart
} from './routing'
import { getInfluencersSortBy } from './influencers'
import { getPublicationsSortBy } from './publications'
import { getAllowedChartConfigs } from './news/charts'
import { getWhitelabelEmailAddress, getLogo, getBffUrl } from './whitelabel'
import {
  getSelectedMediaReviewNewsIdsForExport,
  getMediaReviewSorting,
  getSelectedMediaReview,
  getMediaReviewSummary,
  getMediaReviewSelectedNews,
  getMediaReviewSelectedNewsIds,
  getSelectedMediaReviewId,
  getMediaReviewNewsUngrouped
} from './media_reviews'
import {
  getViewConfigNewsGroupPagingEntity,
  getViewConfigNewsGroupPagingField,
  getViewConfigIndexType,
  getViewConfigModuleName,
  getRequiredFilters
} from './news/view_config'
import { getI18n } from './i18n'
import { getPageIdentitiesShowInactive } from './page_identities'
import { getAllowedStaticMediaReviewCodes, getStaticMediaReviewTypesForSubscription } from './statics'
import { getDarknetSortDirection, getDarknetSortBy, getActiveDarknetFilters, getActiveDarknetNumberFilters } from './darknet'
import { getSelectedChartData, getDashboardSelectedChartSavedSearchModuleName } from './dashboard'
import {
  getSavedDashboardFetchRequestRunning,
  getSavedDashboardDisableCookies,
  getSavedDashboard
} from './saved_dashboard'
import { getCDeskContentSearchQueries, getCDeskCampaignSearchQueries } from './content_desk'
import {
  getCmContactSearchQueries,
  getCmDistributionListSearchQueries,
  getCmDistributionListContactsSearchQueries
} from './contact_management'
import { getAiAnalysisAnalysis } from './ai'

export const getSelectedTopicIds = createImmutableSelector(
  getAllSelectedTopicIds,
  getAllowedStaticMediaReviewCodes,
  (topicIds, allowed) => {
    const allowedIds = allowed.map(a => a.get('id'))

    return topicIds.filter(t => allowedIds.includes(t))
  }
)

export const isGroupedShoppingCart = state => (
  isShoppingCart(state) && getShoppingCartTabIndex(state) === 0
  && getShoppingCartCodes(state).size > 0 && !getShoppingCartIsStructuredExport(state)
)

export const isUngroupedShoppingCart = state => (
  isShoppingCart(state) && (
    getShoppingCartTabIndex(state) === 1
    || !getShoppingCartCodesSize(state)
    || getShoppingCartIsStructuredExport(state)
  )
)

export const getNewsIdsForExport = (state, isPdfPreview) => {
  let newsIds = getShoppingCartNewsIds(state)

  if (isPdfPreview) {
    return fromJS([])
  }

  if (isMediaReviewDetail(state)) {
    newsIds = getSelectedMediaReviewNewsIdsForExport(state)

    const mediaReviewSelectedNewsIds = getMediaReviewSelectedNewsIds(state)

    if (!mediaReviewSelectedNewsIds.isEmpty()) {
      return newsIds.filter(id => mediaReviewSelectedNewsIds.includes(id.first()))
    }

    return newsIds
  }

  const shoppingCartSelectedNewsIds = getShoppingCartSelectedNewsIds(state)

  if (!shoppingCartSelectedNewsIds.isEmpty()) {
    return newsIds.filter(id => shoppingCartSelectedNewsIds.includes(id.first()))
  }

  return newsIds
}

export const getNewsForAiAnalysis = state => {
  let news = getShoppingCartNews(state)
  let selectedIds = getShoppingCartSelectedNewsIds(state)

  if (isMediaReviewDetail(state)) {
    news = getMediaReviewNewsUngrouped(state)

    selectedIds = getMediaReviewSelectedNewsIds(state)
  }

  if (!selectedIds.isEmpty()) {
    news = news.filter(n => selectedIds.includes(n.get('id')))
  }

  return news
}

const filterNewsByGroupedSelection = (news, groupedSelection) => {
  const selectedCodes = groupedSelection.reduce((acc, value) => {
    const [codeId, newsId] = value.split('_')

    if (acc.has(codeId)) {
      return acc.update(codeId, ids => ids.push(parseInt(newsId, 10)))
    }

    return acc.set(codeId, fromJS([parseInt(newsId, 10)]))
  }, Map({}))

  return news
    .filter(n => selectedCodes.has(n.get('code_id') === null ? 'null' : n.get('code_id').toString()))
    .map(n => n.update('news_ids', ids => ids.filter(
      id => selectedCodes.get(n.get('code_id') === null ? 'null' : n.get('code_id').toString()).includes(id)
    )))
    .filter(n => !n.get('news_ids').isEmpty())
}

export const getMainNewsIdsOrSortingForExport = (state, isPdfPreview) => {
  let news = fromJS([])

  if (isPdfPreview) {
    return news
  }

  if (isMediaReviewDetail(state)) {
    news = getMediaReviewSorting(state)

    const mediaReviewSelectedNews = getMediaReviewSelectedNews(state)

    if (!mediaReviewSelectedNews.isEmpty()) {
      news = filterNewsByGroupedSelection(news, mediaReviewSelectedNews)
    }
  } else if (isGroupedShoppingCart(state)) {
    news = getShoppingCartCodes(state).map(code => (fromJS({
      code_id: code.get('id'),
      news_ids: code.get('news').map(n => n.get('id'))
    })))
    const shoppingCartSelectedNews = getShoppingCartSelectedNews(state)

    if (!shoppingCartSelectedNews.isEmpty()) {
      news = filterNewsByGroupedSelection(news, shoppingCartSelectedNews)
    }
  } else if (isUngroupedShoppingCart(state)) {
    news = getShoppingCartNewsIds(state)

    const shoppingCartSelectedNewsIds = getShoppingCartSelectedNewsIds(state)

    if (!shoppingCartSelectedNewsIds.isEmpty()) {
      news = news.filter(n => shoppingCartSelectedNewsIds.toList().includes(n.first()))
    }

    news = news.map(n => n.first())
  }

  return news
}

const getSelectedNewsFromShoppingCart = state => {
  const shoppingCartSelectedNewsIds = getShoppingCartSelectedNewsIds(state)
  let news = getShoppingCartNews(state)

  if (!shoppingCartSelectedNewsIds.isEmpty()) {
    news = news.filter(n => shoppingCartSelectedNewsIds.includes(n.get('id')))
  }

  return news
}

export const getNewsForRssExport = state => {
  const news = getSelectedNewsFromShoppingCart(state)

  return news.map(n => ({
    id: n.get('id'),
    headline: n.get('headline'),
    text: n.get('snippet'),
    article_date: n.get('articleDate'),
    url: n.get('originalUrl'),
    publication: {
      id: n.get('publication').get('id'),
      name: n.get('publication').get('name'),
      reach: n.get('keyFigures').get('reach')
    },
    codes: n.get('codes').map(c => c.get('name'))
  })).toJS()
}

export const getExportBody = (state, type, preview, mode) => {
  const settings = getExportSettings(state)
  const config = getConfig(state)
  const hasTonality = config.get('hasTonality')
  const timezone = getTimezone(state)
  const user = getUser(state)
  const addAllowedCodeIds = isNewsBoard(state)
  const useMediaReviewCodesAsAnalysisCodes = getNewsFiltersAnalysisUseMediaReviewCodes(state)

  const i18n = getI18n(state)
  const dispatchConfig = settings.get(type === 'pdf' ? 'pdfDispatchConfig' : 'dispatchConfig').toJS()
  const dispatchConfigId = dispatchConfig.id
  const { mediaReviewHtmlConfig: { contactMail, contactName, customGreeting, customText } } = dispatchConfig
  dispatchConfig.mediaReviewHtmlConfig.customGreeting = (
    (customGreeting === null && !dispatchConfigId) ? i18n.get('custom_greeting_placeholder') : (customGreeting || '')
  )
  dispatchConfig.mediaReviewHtmlConfig.customText = (
    (customText === null && !dispatchConfigId) ? i18n.get('custom_text_placeholder') : (customText || '')
  )

  if (contactMail === '') {
    delete dispatchConfig.mediaReviewHtmlConfig.contactMail
  }

  if (contactName === '') {
    delete dispatchConfig.mediaReviewHtmlConfig.contactName
  }

  let startDate = settings.get('startDate')
  let endDate = settings.get('endDate')

  if (type.startsWith('backwards')) {
    startDate = startDate ? moment(getSelectedDateFrom(state)).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null
    endDate = endDate ? moment(getSelectedDateTo(state)).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') : null
  } else {
    startDate = startDate ? moment(startDate).format('YYYY-MM-DD') : null
    endDate = endDate ? moment(endDate).format('YYYY-MM-DD') : null
  }

  let mediaReviewName = ''
  let mediaReviewSummary
  let mediaReviewId

  if (isMediaReviewDetail(state)) {
    mediaReviewName = getSelectedMediaReview(state).get('name')
    mediaReviewSummary = getMediaReviewSummary(state)
    mediaReviewId = getSelectedMediaReview(state).get('id')
  }

  const isCoverPreview = Boolean(type === 'pdf' && preview && mode === 'cover')
  const isEndPagePreview = Boolean(type === 'pdf' && mode === 'endPage')
  const isPdfPreview = isCoverPreview || isEndPagePreview

  return {
    news: getMainNewsIdsOrSortingForExport(state, isPdfPreview).toJS(),
    news_ids: getNewsIdsForExport(state, isPdfPreview).toJS(),
    cover_only: isCoverPreview,
    end_page_only: isEndPagePreview,
    type,
    settings: {
      attach_summaries: settings.get('attachSummaries'),
      automatic_translation_enabled: settings.get('automaticTranslationEnabled'),
      ai_summary_enabled: settings.get('aiSummaryEnabled'),
      ai_summary: getAiAnalysisAnalysis(state),
      content_language: settings.get('contentLanguage'),
      template_language: settings.get('templateLanguage'),
      show_cover: settings.get('showCover'),
      show_end_page: settings.get('showEndPage'),
      show_table_of_contents: settings.get('showTableOfContents'),
      cover_id: settings.get('coverId'),
      dispatch_config: decamelizeKeysDeep(dispatchConfig),
      show_article_text: settings.get('showArticleText'),
      pdf_cover_title: settings.get('pdfCoverTitle'),
      export_filename: settings.get('exportFilename'),
      columns: settings.get('columns'),
      has_tonality: hasTonality,
      start_date: startDate,
      end_date: endDate,
      media_review_name: mediaReviewName,
      media_review_summary: mediaReviewSummary,
      media_review_id: mediaReviewId,
      news_limit: preview ? 5 : null,
      allowed_code_ids: addAllowedCodeIds ? user.get('allowedNewsCodeIds').toJS() : [],
      selected_analysis_code_ids: getNewsFiltersAnalysisSelectedCodeIdsForExport(state).toJS(),
      grouped_analysis_code_ids: getNewsFiltersAnalysisGroupedCodeIdsForExport(state).toJS(),
      restricted_analysis_code_ids: getNewsFiltersAnalysisRestrictedCodeIds(state).toJS(),
      selected_statement_tonalities: getNewsFiltersAnalysisStatementTonalityValues(state).toJS(),
      article_level: getNewsFiltersAnalysisArticleLevel(state),
      use_media_review_codes_as_analysis_codes: useMediaReviewCodesAsAnalysisCodes,
      timezone
    }
  }
}

export const getRssExportBody = state => {
  const newsIds = getNewsIdsForExport(state, false)
  let news = newsIds.flatten()

  if (isSearchPool(state)) {
    news = getNewsForRssExport(state)
  }

  return {
    type: 'rss',
    news,
    news_ids: newsIds
  }
}

const getStructuredFormatBody = (state, type) => {
  const newsIds = getNewsIdsForExport(state, false)
  let news = newsIds.flatten()

  if (isSearchPool(state)) {
    news = getSelectedNewsFromShoppingCart(state)
  }

  return ({
    type,
    news,
    news_ids: newsIds
  })
}

export const getXmlExportBody = state => getStructuredFormatBody(state, 'xml')
export const getJsonExportBody = state => getStructuredFormatBody(state, 'json')

export const getMailBody = (state, to, subject, text, from, scheduledFor) => {
  const result = {
    to,
    subject,
    from: from || getWhitelabelEmailAddress(state)
  }

  if (['html', 'txt', 'backwards_news_html'].includes(getExportOptions(state).get('selectedFormat'))) {
    result.body_url = getCurrentDocumentUrl(state)
    result.attachments = getCurrentDocumentAttachments(state).map(a => ({
      mime_type: a.get('mimeType'),
      name: a.get('filename'),
      url: a.get('url'),
      size: a.get('size')
    }))
  } else {
    result.text = text.replace(/\n/g, '<br />')
    result.attachments = [
      {
        name: getCurrentDocumentFilename(state),
        url: getCurrentDocumentUrl(state),
        mime_type: getCurrentDocumentMimeType(state),
        size: getCurrentDocumentSize(state)
      }
    ]
  }

  const lastExport = getLastExport(state)

  let exportConfig = {
    type: lastExport.get('type'),
    timezone: getTimezone(state)
  }

  if (scheduledFor) {
    exportConfig = { ...exportConfig, scheduled_for: scheduledFor }
  }

  if (isMediaReviewDetail(state)) {
    exportConfig = { ...exportConfig, media_review_id: getSelectedMediaReviewId(state), module_name: 'media_reviews' }
  } else {
    exportConfig = { ...exportConfig, module_name: getViewConfigModuleName(state) }
  }

  return { ...result, export_config: exportConfig }
}

export const getSearchBody = (state, dateFrom, dateTo) => ({
  author: getSelectedAuthorFilters(state),
  date_type: getSelectedDateType(state),
  date_interval: getSelectedDateInterval(state),
  grouping_type: getNewsGroupingType(state),
  index_type: getViewConfigIndexType(state),
  news: getSelectedNewsFilters(state, dateFrom, dateTo),
  page_identity: getSelectedPageIdentityFilters(state),
  publication: getSelectedPublicationFilters(state),
  outlink: getSelectedOutlinkFilters(state),
  size: NewsPageSize,
  sort_by: getSortBy(state),
  sort_direction: getSortDirection(state),
  timezone: getTimezone(state),
  module_name: getViewConfigModuleName(state)
})

const addQueries = (data, filters, sourceKey, targetKey) => {
  const queries = filters.get(sourceKey).filter(f => !f.get('inverted')).toJS()
  const excludedQueries = filters.get(sourceKey).filter(f => f.get('inverted')).toJS()

  let result = data

  if (queries.length) {
    result = { ...result, [targetKey]: queries.map(el => (el.id || el.value)) }
  }

  if (excludedQueries.length) {
    result = { ...result, [`exclude_${targetKey}`]: excludedQueries.map(el => (el.id || el.value)) }
  }

  return result
}

export const getCDeskSearchBody = state => {
  const contentSearchQueries = getCDeskContentSearchQueries(state)
  const campaignSearchQueries = getCDeskCampaignSearchQueries(state)

  const contentIncludes = contentSearchQueries.filter(f => !f.get('inverted')).toJS()
  const contentExcludes = contentSearchQueries.filter(f => f.get('inverted')).toJS()

  const campaignIncludes = campaignSearchQueries.filter(f => !f.get('inverted')).toJS()
  const campaignExcludes = campaignSearchQueries.filter(f => f.get('inverted')).toJS()

  return {
    contents: {
      includes: contentIncludes.map(c => ({
        field: c.field.replace('content_', ''),
        value: c.value
      })),
      excludes: contentExcludes.map(c => ({
        field: c.field.replace('content_', ''),
        value: c.value
      }))
    },
    campaigns: {
      includes: campaignIncludes.map(c => ({
        field: c.field.replace('campaign_', ''),
        value: c.value
      })),
      excludes: campaignExcludes.map(c => ({
        field: c.field.replace('campaign_', ''),
        value: c.value
      }))
    }
  }
}

export const getCdSearchBody = state => {
  const contactSearchQueries = getCmContactSearchQueries(state)
  const distributionListSearchQueries = getCmDistributionListSearchQueries(state)
  const distributionListContactSearchQueries = getCmDistributionListContactsSearchQueries((state))

  let contactIncludes = contactSearchQueries.filter(f => !f.get('inverted')).toJS()
  let distributionListIncludes = distributionListSearchQueries.filter(f => !f.get('inverted')).toJS()
  let distributionListContactsIncludes = distributionListContactSearchQueries.filter(f => !f.get('inverted')).toJS()

  if (contactIncludes.length) {
    const nameFilter = contactIncludes.filter(c => c.field === 'contact_name')
    const topicTagFilter = contactIncludes.filter(c => c.field === 'contact_tag')
    const result = []
    nameFilter.forEach(filter => {
      result.push([
        { field: 'searchable', value: filter.value }
      ])
    })
    topicTagFilter.forEach(filter => {
      result.push([
        { field: 'tags', value: filter.value }
      ])
    })
    contactIncludes = result.flat()
  }

  if (distributionListIncludes.length) {
    const nameFilter = distributionListIncludes.filter(c => c.field === 'distribution_list_name')
    const result = []
    nameFilter.forEach(filter => {
      result.push([
        { field: 'name', value: filter.value, comparison: 'contains' }
      ])
      result.push([
        { field: 'description', value: filter.value, comparison: 'contains' }
      ])
    })
    distributionListIncludes = result.flat()
  }

  if (distributionListContactsIncludes.length) {
    const nameFilter = distributionListContactsIncludes.filter(c => c.field === 'distribution_list_contacts')
    const result = []
    nameFilter.forEach(filter => {
      result.push([
        { field: 'distribution_lists', value: filter.value }
      ])
    })
    distributionListContactsIncludes = result.flat()
  }

  return {
    contacts: {
      includes: [...contactIncludes, ...distributionListContactsIncludes]
    },
    distributionLists: {
      includes: distributionListIncludes
    }
  }
}

export const getDarknetSearchBody = state => {
  const activeDarknetFilters = getActiveDarknetFilters(state)
  const activeDarknetNumberFilters = getActiveDarknetNumberFilters(state)

  let filters = {}
  filters = addQueries(filters, activeDarknetFilters, 'networks', 'network')
  filters = addQueries(filters, activeDarknetFilters, 'languages', 'language')
  filters = addQueries(filters, activeDarknetFilters, 'darknetSearchQueries', 'queries')
  filters = addQueries(filters, activeDarknetFilters, 'searchQueries', 'darkowlSearchQueryId')
  filters.minHackishness = activeDarknetNumberFilters.get('minHackishness')

  return {
    filters,
    dateFrom: getSelectedDateFrom(state),
    dateTo: getSelectedDateTo(state),
    sortBy: getDarknetSortBy(state),
    sortDirection: getDarknetSortDirection(state)
  }
}

export const getSearchBodyMaxSize = (state, type) => {
  const searchBody = getSearchBody(state)
  let size = getExportLimit(state)
  let { news } = searchBody

  if (type.match('xlsx')) {
    size = getXlsxExportLimit(state)

    if (type === 'backwards_news_xlsx') {
      size = getBackwardsNewsXlsxLimit(state)
    }

    news = {
      ...searchBody.news,
      booleans: {
        ...searchBody.news.booleans,
        global_clusters: false
      }
    }
  }

  return {
    ...searchBody,
    from: 0,
    size,
    news
  }
}

export const getInfluencersSearchBody = state => {
  const body = getSearchBody(state)
  const sortBy = getInfluencersSortBy(state)

  return {
    ...body,
    size: 20,
    sort_by: sortBy
  }
}

export const getPublicationsSearchBody = state => {
  const body = getSearchBody(state)
  const sortBy = getPublicationsSortBy(state)

  // always add newsguard ranks as upper- and lowercase,
  // because the groot_publications does only understand lowercase and groot_news only uppercase
  if (body.publication.newsguard_rank) {
    body.publication.newsguard_rank = body.publication.newsguard_rank.map(x => [x, x.toLowerCase()]).flat()
  }

  return {
    ...body,
    size: 30,
    sort_by: sortBy
  }
}

export const getPageIdentitiesSearchBody = state => {
  const body = getSearchBody(state)
  const sortBy = 'reach'

  return {
    ...body,
    show_inactive: getPageIdentitiesShowInactive(state),
    size: 30,
    sort_by: sortBy
  }
}

export const getPageIdentityPostsSearchBody = state => {
  const body = getSearchBody(state)

  return { ...body, size: 4 }
}

export const getFetchMoreNewsBody = (state, id) => {
  let result = getSearchBody(state)
  const paging = getPagingByGroupId(state, id)

  if (paging) {
    result = {
      ...result,
      size: paging.get('size'),
      from: paging.get('from')
    }
  } else {
    result = {
      ...result,
      size: 50,
      from: 10
    }
  }

  const entity = getViewConfigNewsGroupPagingEntity(state)

  // In a drill down there is no view config for news groups or filters
  if (!entity) {
    return result
  }

  const field = getViewConfigNewsGroupPagingField(state)

  let filters = getSelectedNewsFilters(state)

  if (entity === 'publication') {
    filters = getSelectedPublicationFilters(state)
  }

  // Prevent from paging in categories that came from clustered news
  if (filters[field] && filters[field].length && filters[field].indexOf(id) === -1) {
    filters[field] = [-99999999999]
  } else {
    filters[field] = [id]
  }

  return { ...result, [entity]: filters }
}

export const getFetchClusterStatsBody = (state, groupId, newsId, clusterId) => {
  const result = getFetchMoreNewsBody(state, groupId)

  return {
    ...result,
    from: 0,
    size: 10000,
    sort_by: 'reach',
    news: {
      ...result.news,
      cluster_id: [clusterId],
      exclude_ids: [newsId],
      booleans: {
        ...result.news.booleans,
        global_clusters: false
      }
    }
  }
}

export const getIsRunningByGroupId = (state, id) => {
  const groups = getNewsGroups(state)

  return groups && groups.getIn([id.toString(), 'loadingMore'])
}

export const getConfigUpdateBody = state => {
  const config = getConfig(state)

  return {
    data: {
      date_filter_setting_id: config.get('dateFilterSettingId'),
      default_date_type: config.get('defaultDateType'),
      default_news_page_view: config.get('defaultNewsPageView'),
      groot_grouping_setting_id: config.get('grootGroupingSettingId'),
      default_sorting_id: config.get('defaultSortingId'),
      colors: decamelizeKeysDeep(config.get('colors').toJS()),
      export_filename: config.get('exportFilename'),
      default_pdf_dispatch_config_id: config.get('defaultPdfDispatchConfigId'),
      pdf_cover_title: config.get('pdfCoverTitle'),
      show_flags: config.get('showFlags'),
      expand_topics: config.get('expandTopics'),
      show_summaries: config.get('showSummaries'),
      show_snippets: config.get('showSnippets'),
      saved_search_id: config.get('savedSearchId'),
      initial_route: config.get('initialRoute') === '' ? null : config.get('initialRoute')
    }
  }
}

export const getSaveSubscriptionsBody = state => ({
  topic_ids: getSelectedTopicIds(state),
  edition_ids: getSelectedEditionIds(state)
})

export const isBackButtonPage = state => {
  if (isDarknetQueryManager(state)) {
    return true
  }

  if (isDashboard(state)) {
    return false
  }

  if (isAdministrationSubmodule(state)) {
    return false
  }

  if (isContentDeskContentsAndCampaigns(state)) {
    return true
  }

  if (isContentDeskSubmodule(state)) {
    return false
  }

  if (isContactManagementSubmodule(state)) {
    return false
  }

  const matches = getCurrentPath(state).match(/\/[^/]+/g)

  return Boolean(matches && matches.length >= 3)
}

const removeKeysFromConfig = (oldConfigs, keys) => {
  let configs = oldConfigs
  configs = configs.deleteAll(keys)

  configs = configs.map(value => {
    if (Map.isMap(value) && value.has('chartTypes')) {
      return value.update(
        'chartTypes',
        chartTypes => chartTypes.map(
          v => (
            v
              .update('secondLevels', sL => (List.isList(sL) ? sL.filter(s => keys.indexOf(s.get('name')) === -1) : sL))
              .update('thirdLevels', tL => (List.isList(tL) ? tL.filter(s => keys.indexOf(s.get('name')) === -1) : tL))
          )
        )
      )
    }

    return value
  })

  return configs
}

export const getChartConfigs = state => {
  const hasTonality = getConfig(state).get('hasTonality')
  const isSuper = getConfig(state).get('isSuper')
  const capabilities = getCapabilities(state)
  const userId = getUser(state).get('id')
  let configs = getAllowedChartConfigs(state)

  if (!capabilities.get(Capabilities.HAS_CUSTOM_TAGS)) {
    configs = removeKeysFromConfig(configs, ['customTags'])
  }

  if (!capabilities.get(Capabilities.HAS_NEWSGUARD)) {
    configs = removeKeysFromConfig(configs, ['newsguardScoreRanges', 'newsguardRanks', 'newsguardScore', 'newsguardOrientations'])
  }

  if (!capabilities.get(Capabilities.HAS_MOZ)) {
    configs = removeKeysFromConfig(configs, ['mozDomainAuthority', 'mozPageAuthority', 'mozSpamScore'])
  }

  if (!hasTonality) {
    configs = removeKeysFromConfig(configs, ['tonality', 'tonalities'])
  }

  if (!isSuper) {
    configs = removeKeysFromConfig(configs, ['interfaces', 'suppliers'])
  }

  if (!isDashboard(state)) {
    configs = removeKeysFromConfig(configs, ['savedSearches'])
  }

  if (userId === 56558 || userId === 57869) {
    configs = removeKeysFromConfig(
      configs,
      [
        'influencers',
        'authorSexes',
        'authorCities',
        'authorCountries',
        'authorBirthdateRanges',
        'identityCount',
        'karma',
        'visits',
        'ave',
        'circulation'
      ]
    )
  }

  return configs
}

export const getDashboardSelectedChartSortingOptions = createImmutableSelector(
  getSelectedChartData,
  getDashboardSelectedChartSavedSearchModuleName,
  getCapabilities,
  chart => {
    if (['topInfluencers', 'topPublications'].indexOf(chart.get('firstLevel')) !== -1) {
      const sortOptions = [
        'buzz',
        'reach',
        'interactions',
        'name'
      ]

      return sortOptions
    }

    return SortingOptions
  }
)

const forbiddenQueryPatterns = [
  {
    pattern: /^\*+$/,
    allowedCount: 0
  },
  {
    pattern: /^-\S+$/,
    allowedCount: 0
  },
  {
    pattern: /^NOT \S+$/,
    allowedCount: 0
  }
]

export const isValidSearch = state => {
  const requiredFilters = getRequiredFilters(state)

  if (requiredFilters.isEmpty()) {
    return true
  }

  const activeFilters = getActiveFilters(state)

  const isFilterActive = filter => activeFilters.get(filter) && !activeFilters.get(filter).isEmpty()

  const requiredFiltersSet = requiredFilters.isEmpty() || requiredFilters.some(isFilterActive)
  const atLeastOneMust = activeFilters.valueSeq().toList().flatten(true).some(f => !f.get('inverted'))

  const newsQueries = activeFilters.get('newsQueries') || fromJS([])
  const authorQueries = activeFilters.get('authorQueries') || fromJS([])
  const publicationQueries = activeFilters.get('publicationQueries') || fromJS([])
  const queries = newsQueries.concat(authorQueries, publicationQueries)

  const validQueries = !queries.some(q => forbiddenQueryPatterns.some(f => (q.get('value').match(f.pattern) || []).length > f.allowedCount))

  return validQueries && requiredFiltersSet && atLeastOneMust
}

const defaultChartBodySize = 10

export const getChartBodySize = chart => {
  if ([ChartTypes.TREEMAP, ChartTypes.TAG_CLOUD].indexOf(chart.get('type')) !== -1) {
    return defaultChartBodySize * 3
  }

  return defaultChartBodySize
}

const fixLegacySecondLevels = secondLevel => {
  if (secondLevel === 'tonality') {
    return 'tonalities'
  }

  if (secondLevel === 'sentiment') {
    return 'sentiments'
  }

  return secondLevel
}

const defaultChartBodySizeForExport = 100

export const getChartBodySizeForExport = (chart, type) => {
  if (type === 'xlsx') {
    return 10000
  }

  if (['emojis', 'hashtags', 'entities', 'analysisCodes'].indexOf(chart.get('firstLevel')) !== -1) {
    return defaultChartBodySizeForExport * 3
  }

  if (['entitiesPerson', 'entitiesOrganization', 'entitiesTopic', 'entitiesProduct'].indexOf(chart.get('firstLevel')) !== -1) {
    return defaultChartBodySizeForExport * 10
  }

  if (type === 'xlsx' && ['publications'].indexOf(chart.get('firstLevel')) !== -1 && ['reach'].indexOf(chart.get('thirdLevel')) !== -1) {
    return defaultChartBodySizeForExport * 5
  }

  return defaultChartBodySizeForExport
}

export const getChartBody = (state, chart, searchBody, size) => {
  const timezone = getTimezone(state)
  const i18n = getI18n(state)
  const logo = getLogo(state)
  const autoLabel = !!chart.getIn(['opts', 'autoLabel'], true)

  const newSize = size || chart.getIn(['opts', 'size']) || getChartBodySize(chart)
  const label = generateChartLabel(chart, i18n, autoLabel)
  const topLabel = generateChartTopLabel(chart, i18n, autoLabel)
  const chartOpts = decamelizeKeysDeep((chart.get('opts') || fromJS({})).deleteAll(
    ['googleAnalytics', 'facebookAnalytics', 'linkedInAnalytics', 'moz', 'twitterAnalytics']
  ).toJS())

  const defaultBody = {
    data_source: chart.get('dataSource', ChartDataSources.PRESSRELATIONS_NEWS),
    bucket: chart.get('firstLevel'),
    metric: chart.get('thirdLevel'),
    group_by: chart.get('secondLevel'),
    date_from: chart.get('dateFrom'),
    date_to: chart.get('dateTo'),
    date_range: chart.get('dateRange'),
    date_interval: chart.get('dateInterval'),
    type: decamelize(chart.get('type')),
    ai_enabled: chart.get('aiEnabled'),
    chart_opts: chartOpts,
    label,
    top_label: topLabel,
    logo,
    timezone
  }

  let body

  if (isPressrelationsNewsChart(chart)) {
    const savedSearchId = chart.get('savedSearchId')

    if (savedSearchId || searchBody) {
      const opts = {
        date_type: chart.get('dateType'),
        saved_search_id: savedSearchId,
        additional_saved_search_ids: chart.get('additionalSavedSearchIds'),
        grouping_type: chart.get('groupingType'),
        sort_by: chart.get('sortBy'),
        // Need this in Opts as well for now, because of legacy
        date_range: chart.get('dateRange'),
        size: newSize
      }

      // Charts Module
      if (searchBody) {
        opts.search_params = searchBody

        // Dashboard
      } else {
        opts.saved_search_id = savedSearchId
      }

      body = {
        ...defaultBody,
        data_source_opts: opts,
        bucket: decamelize(chart.get('firstLevel')),
        metric: chart.get('thirdLevel') ? decamelize(chart.get('thirdLevel')) : null,
        group_by: chart.get('secondLevel') ? decamelize(fixLegacySecondLevels(chart.get('secondLevel'))) : null
      }
    }
  } else if (isGoogleAnalyticsChart(chart)) {
    body = {
      ...defaultBody,
      data_source_opts: decamelizeKeysDeep((chart.getIn(['opts', 'googleAnalytics']) || fromJS({})).toJS())
    }
  } else if (isMozChart(chart)) {
    body = {
      ...defaultBody,
      data_source_opts: decamelizeKeysDeep((chart.getIn(['opts', 'moz']) || fromJS({})).toJS())
    }
  } else if (isYoutubeAnalyticsChart(chart)) {
    body = {
      ...defaultBody,
      data_source_opts: {}
    }
  } else if (isLinkedInAnalyticsChart(chart)) {
    body = {
      ...defaultBody,
      data_source_opts: decamelizeKeysDeep((chart.getIn(['opts', 'linkedInAnalytics']) || fromJS({})).toJS())
    }
  } else if (isFacebookAnalyticsChart(chart)) {
    body = {
      ...defaultBody,
      data_source_opts: decamelizeKeysDeep((chart.getIn(['opts', 'facebookAnalytics']) || fromJS({})).toJS()),
      bucket: decamelize(chart.get('firstLevel'))
    }
  } else if (isTwitterAnalyticsChart(chart)) {
    body = {
      ...defaultBody,
      data_source_opts: decamelizeKeysDeep((chart.getIn(['opts', 'twitterAnalytics']) || fromJS({})).toJS()),
      bucket: decamelize(chart.get('firstLevel'))
    }
  } else if (isImageChart(chart)) {
    body = {
      ...defaultBody,
      data_source_opts: {},
      bucket: 'image'
    }
  } else if (isTextChart(chart)) {
    body = {
      ...defaultBody,
      data_source_opts: {},
      bucket: 'text'
    }
  } else if (isExternalWidgetChart(chart)) {
    body = {
      ...defaultBody,
      data_source_opts: {},
      bucket: 'external_widget'
    }
  }

  return body
}

export const getDarknetQueries = state => {
  const config = getConfig(state)

  return config.get('darkowlScoreQueries', List())
    .map(q => q.set('type', 'score'))
    .concat(
      config.get('darkowlSearchQueries', List()).map(q => q.set('type', 'search'))
    )
    .sortBy(q => q.get('name'))
}

export const getShowCookieDialog = state => {
  if (getCookieOption(state) && getCookieOption(state) !== '') {
    return false
  }

  if (isInfoboard(state)) {
    const savedDashboard = getSavedDashboard(state)

    return (
      !getSavedDashboardDisableCookies(state)
      && !getSavedDashboardFetchRequestRunning(state)
      && !(!savedDashboard || savedDashboard.isEmpty())
    )
  }

  return !isExternalChart(state) && !isSavedChart(state)
}

export const getChartExternalWidgetUrl = (state, chart) => {
  if (chart.getIn(['opts', 'customWidgetUrl'])) {
    return chart.getIn(['opts', 'customWidgetUrl'])
  }

  const queryParams = new URLSearchParams({
    widget_content: chart.getIn(['opts', 'widgetContent'])
  })

  return `${getBffUrl(state)}/charts/render_widget?${queryParams.toString()}`
}

export const getSelectedCodeFilters = state => {
  const types = getStaticMediaReviewTypesForSubscription(state)
  const selectedTypeIds = getSelectedEditionIds(state)
  const codeFilters = types
    .filter(t => selectedTypeIds.includes(t.get('id')))
    .map(t => t.get('codeFilter'))

  if (codeFilters.some(f => f.isEmpty())) {
    return List()
  }

  return codeFilters.flatten()
}
