export const getNewsExport = state => state.news.get('export')

// UI
export const getExportOptions = state => getNewsExport(state).get('ui')
export const getCurrentDocumentUrl = state => getExportOptions(state).getIn(['currentDocument', 'url'])
export const getCurrentDocumentFilename = state => getExportOptions(state).getIn(['currentDocument', 'filename'])
export const getCurrentDocumentSize = state => getExportOptions(state).getIn(['currentDocument', 'size'])
export const getCurrentDocumentMimeType = state => getExportOptions(state).getIn(['currentDocument', 'mimeType'])
export const getCurrentDocumentAttachments = state => getExportOptions(state).getIn(['currentDocument', 'attachments'])
export const getSendingMail = state => getExportOptions(state).get('sendingMail')
export const getExportValidatingSpf = state => getExportOptions(state).get('validatingSpf')
export const getExportValidSpf = state => getExportOptions(state).get('validSpf')
export const getExportSelectedFormat = state => getExportOptions(state).get('selectedFormat')
export const getExportShowExportDialog = state => getExportOptions(state).get('showExportDialog')

export const getExportProgress = state => getNewsExport(state).get('progress')
export const getCurrentExportProgress = state => getExportProgress(state).get('current')
export const getEstimatedExportDuration = state => getExportProgress(state).get('estimatedDuration')
export const getElapsedExportTime = state => getExportProgress(state).get('elapsedTime')

// SETTINGS
export const getExportSettings = state => getNewsExport(state).get('settings')
export const getExportSettingsStartDate = state => getExportSettings(state).get('startDate')
export const getExportSettingsEndDate = state => getExportSettings(state).get('endDate')

export const getExportSelectedDispatchConfig = state => getExportSettings(state).get('dispatchConfig')
export const getExportSelectedDispatchConfigId = state => getExportSelectedDispatchConfig(state).get('id')
export const getExportSelectedDispatchConfigFilename = state => getExportSelectedDispatchConfig(state).get('filename')
export const getExportMediaReviewHtmlConfig = state => getExportSelectedDispatchConfig(state).get('mediaReviewHtmlConfig')
export const getUploadedHeaderLogoFilename = state => getExportMediaReviewHtmlConfig(state).get('headerLogoFilename')
export const getUploadedAdvertisingBannerFilename = state => getExportMediaReviewHtmlConfig(state).get('advertisingBannerFilename')

export const getExportSelectedPdfDispatchConfig = state => getExportSettings(state).get('pdfDispatchConfig')
export const getExportSelectedPdfDispatchConfigId = state => getExportSelectedPdfDispatchConfig(state).get('id')
export const getExportSelectedPdfDispatchConfigFilename = state => getExportSelectedPdfDispatchConfig(state).get('filename')
export const getExportMediaReviewPdfConfig = state => getExportSelectedPdfDispatchConfig(state).get('mediaReviewPdfConfig')

export const getExportSettingsAutomaticTranslationEnabled = state => getExportSettings(state).get('automaticTranslationEnabled')
export const getExportSettingsContentLanguage = state => getExportSettings(state).get('contentLanguage')
export const getExportSettingsTemplateLanguage = state => getExportSettings(state).get('templateLanguage')

export const getExportSettingsAiSummaryEnabled = state => getExportSettings(state).get('aiSummaryEnabled')

// LAST EXPORT
export const getLastExport = state => getNewsExport(state).get('lastExport')

// DISPATCH LOG
export const getDispatchLog = state => getNewsExport(state).get('dispatchLog')
export const getDispatchLogData = state => getDispatchLog(state).get('data')

export const getDispatchLogUi = state => getDispatchLog(state).get('ui')
export const getDispatchLogLoading = state => getDispatchLogUi(state).get('loading')
