import {
  all,
  call,
  put,
  select,
  takeEvery
} from 'redux-saga/effects'

import * as Actions from 'actions/themax'
import { rangeToDates } from 'utils/date'
import * as Selectors from 'selectors'

export function* uiApplyDateFilter({ payload: { dateFrom, dateTo, range } }) {
  yield put(Actions.toggleDateForm(false))
  yield put(Actions.changeDate({ from: dateFrom, to: dateTo, range }))
  yield put(Actions.themaxSearchRequestStart())
}

export function* uiChooseQuickSelect({ payload: range }) {
  const { from, to } = yield call(rangeToDates, range)

  if (from || to) {
    yield put(Actions.uiApplyDateFilter({
      dateFrom: from,
      dateTo: to,
      range
    }))
  }
}

export function* uiEnteredQuery({ payload: query }) {
  if (query !== '') {
    const i18n = yield select(Selectors.getI18n)
    const type = yield select(Selectors.getThemaxQueryType)
    const prefix = yield call(i18n.get, type)
    yield put(Actions.addQuery({ type, query, prefix }))
  }

  yield put(Actions.themaxSearchRequestStart())
}

export function* watchUiApplyDateFilter() {
  yield takeEvery(Actions.uiApplyDateFilter, uiApplyDateFilter)
}

export function* watchUiChooseQuickSelect() {
  yield takeEvery(Actions.uiChooseQuickSelect, uiChooseQuickSelect)
}

export function* watchUiEnteredQuery() {
  yield takeEvery(Actions.uiEnteredQuery, uiEnteredQuery)
}

export default function* uiSaga() {
  yield all([
    watchUiApplyDateFilter(),
    watchUiChooseQuickSelect(),
    watchUiEnteredQuery()
  ])
}
