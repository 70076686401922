import { handleActions } from 'redux-actions'
import { fromJS } from 'immutable'
import { isTimeline } from 'utils/chart'
import { isAiEntityKey } from 'utils/ai_entities'

import { FilterNameMapping, ChartTypes } from 'static/constants'

import * as AppActions from 'actions/app'
import * as Actions from 'actions/dashboard'

export const initialState = fromJS({
  chart: {},
  loading: false,
  aiAnalysisLoading: false,
  show: false,
  dateFrom: null,
  dateTo: null,
  dateRange: null,
  dateType: null,
  searchParams: null,
  groupingType: 'channel',
  sortBy: '',
  filterData: null,
  secondFilter: null,
  savedSearchId: null
})

const getFilterKV = (filterName, filterData) => {
  if (filterData.get('id') === -1) {
    return { key: `exclude_${filterName}`, value: filterData.get('excludeIds') }
  }

  return { key: filterName, value: [filterData.get('id')] }
}

const addFilters = (searchParams, field, data, isSecondFilter) => {
  const result = { ...searchParams }
  const filterData = fromJS(data)

  let fieldName = field

  if (field === 'tonality') {
    fieldName = 'tonalities'
  } else if (field === 'sentiment') {
    fieldName = 'sentiments'
  } else if (isAiEntityKey(field)) {
    fieldName = 'aiEntities'
  }

  const newsFilterName = FilterNameMapping.news[fieldName]

  if (newsFilterName) {
    result.news = result.news || {}
    const { key, value } = getFilterKV(newsFilterName, filterData)
    result.news[key] = value
  }

  const publicationFilterName = FilterNameMapping.publication[fieldName]

  if (publicationFilterName) {
    result.publication = result.publication || {}
    const { key, value } = getFilterKV(publicationFilterName, filterData)
    result.publication[key] = value
  }

  const outlinkFilterName = FilterNameMapping.outlink[fieldName]

  if (outlinkFilterName) {
    result.outlink = result.outlink || {}
    const { key, value } = getFilterKV(outlinkFilterName, filterData)
    result.outlink[key] = value
  }

  if (fieldName === 'analysisCodes') {
    result.news = result.news || {}
    result.news.analysis = result.news.analysis || {}

    if (isSecondFilter) {
      const { key, value } = getFilterKV('grouped_code_ids', filterData)
      result.news.analysis[key] = value
    } else {
      const { key, value } = getFilterKV('selected_code_ids', filterData)
      result.news.analysis[key] = value
    }
  }

  if (fieldName === 'influencers') {
    result.news = result.news || { identity_id: [] }
    result.news.identity_id = result.news.identity_id.concat(filterData.get('identityIds').toJS())
  }

  if (fieldName === 'statementTonalities') {
    result.news = result.news || {}
    result.news.analysis = result.news.analysis || {}
    const { key, value } = getFilterKV('statement_tonalities', filterData)
    result.news.analysis[key] = value
  }

  return result
}

const buildSearchParams = (chart, filterData, secondFilter) => {
  let searchParams = {}

  if (filterData) {
    searchParams = addFilters(searchParams, chart.get('firstLevel'), filterData)
  }

  if (secondFilter) {
    searchParams = addFilters(searchParams, chart.get('secondLevel'), secondFilter, true)
  }

  return fromJS(searchParams)
}

export default handleActions({
  [Actions.showDrilldownDialog]: (state, { payload: { chart, d: { filterData }, secondFilter: sF } }) => {
    let searchParams = null
    let dateFrom = null
    let dateTo = null
    let dateRange = null
    let savedSearchId = null
    const dateType = chart.get('dateType') || chart.getIn(['savedSearch', 'dateType'])
    const secondFilter = sF ? fromJS(sF) : null

    if (!isTimeline(chart) || chart.get('type') === ChartTypes.GAUGE) {
      searchParams = buildSearchParams(chart, filterData, secondFilter)
      dateFrom = chart.get('dateFrom')
      dateTo = chart.get('dateTo')
      dateRange = chart.get('dateRange')
    } else {
      dateFrom = filterData.range.from
      dateTo = filterData.range.to
      searchParams = buildSearchParams(chart, null, secondFilter)
    }

    if (chart.get('firstLevel') === 'savedSearches') {
      savedSearchId = filterData.id
    } else if (chart.get('secondLevel') === 'savedSearches' && secondFilter) {
      savedSearchId = secondFilter.get('id')
    } else {
      savedSearchId = chart.getIn(['savedSearch', 'id'])
    }

    return state.merge({
      chart,
      show: true,
      dateFrom,
      dateTo,
      dateRange,
      dateType,
      savedSearchId,
      groupingType: chart.getIn(['savedSearch', 'groupingType']),
      sortBy: chart.getIn(['savedSearch', 'sortBy']),
      searchParams,
      filterData: fromJS(filterData || {}).delete('searchParams'),
      secondFilter
    })
  },
  [Actions.hideDrilldownDialog]: state => state.set('show', false),

  [Actions.loadDrilldownNewsFeedNewsStart]: state => state.set('loading', true),
  [Actions.loadDrilldownNewsFeedNewsError]: state => state.set('loading', false),
  [Actions.loadDrilldownNewsFeedNewsSuccess]: state => state.set('loading', false),

  [Actions.loadDrilldownNewsFeedAiAnalysisStart]: state => state.set('aiAnalysisLoading', true),
  [Actions.loadDrilldownNewsFeedAiAnalysisError]: state => state.set('aiAnalysisLoading', false),
  [Actions.loadDrilldownNewsFeedAiAnalysisSuccess]: state => state.set('aiAnalysisLoading', false),

  [Actions.selectDrilldownGroupingType]: (state, { payload: groupingType }) => state.set('groupingType', groupingType),
  [Actions.selectDrilldownSortBy]: (state, { payload: sortBy }) => state.set('sortBy', sortBy),

  [AppActions.resetState]: () => initialState
}, initialState)
