import { handleActions } from 'redux-actions'
import { fromJS, List } from 'immutable'
import moment from 'moment-timezone'
import camelcase from 'camelcase'

import { datesToRange, calculateIntervals } from 'utils/date'
import { isAiEntityKey } from 'utils/ai_entities'
import { SearchFields, FilterNameMapping } from 'static/constants'

import * as FilterActions from 'actions/filter'
import * as AppActions from 'actions/app'
import * as ConfigActions from 'actions/config'
import * as UserActions from 'actions/user'

const defaultFilterMapping = fromJS(FilterNameMapping)

const defaultFilters = defaultFilterMapping
  .valueSeq()
  .flatMap(v => v.keySeq())
  .reduce((acc, key) => acc.set(key, List([])), fromJS({}))

const analysisFilters = defaultFilterMapping
  .getIn(['news', 'analysis'])
  .keySeq()
  .reduce((acc, key) => acc.set(key, List([])), fromJS({}))

const similarityFilters = defaultFilterMapping
  .getIn(['news', 'similarity'])
  .keySeq()
  .reduce((acc, key) => acc.set(key, List([])), fromJS({}))

const defaultGeoBoundingBox = fromJS({
  topLeft: {
    lat: null,
    lon: null
  },
  bottomRight: {
    lat: null,
    lon: null
  }
})

const resettableFilters = defaultFilters.merge(fromJS({
  influencers: [],

  newsQueries: [],
  authorQueries: [],
  publicationQueries: [],

  analysis: analysisFilters.merge({
    articleLevel: false,
    useMediaReviewCodes: false
  }),

  similarity: similarityFilters,

  geoBoundingBox: defaultGeoBoundingBox,

  booleans: {
    summarizedOnly: false,
    globalClusters: true,
    realPeopleOnly: false,
    excludeVisitorPosts: false,
    excludeComments: false,
    excludeRetweets: false,
    withOutlinksOnly: false,
    withoutOutlinksOnly: false
  }
}))

const { allowedDateIntervals: defaultAllowedDateIntervals } = calculateIntervals(null, new Date(), new Date())

export const initialState = resettableFilters.merge({
  dateFrom: new Date(),
  dateTo: new Date(),
  dateRange: null,
  dateType: null,
  dateInterval: null,
  allowedDateIntervals: defaultAllowedDateIntervals,
  allDateIntervals: defaultAllowedDateIntervals
})

const mapAddedItem = (item, field) => item.merge({
  id: item.get('id'),
  label: item.get('label') || item.get('name'),
  inverted: item.get('inverted') || false,
  field
})

const mapQueryStringQuery = (value, type, label, prefix) => (fromJS({
  label,
  prefix,
  field: SearchFields[`${type.toUpperCase()}_QUERIES`],
  value
}))

const mapSelectedItem = (item, field) => (fromJS(item).merge({
  id: item.id,
  label: item.label || item.name,
  field
}))
const mapSelectedItems = (items, state, field, fieldNameOverride) => {
  if (!items) {
    return fromJS([])
  }

  const currentFilters = state.get(field, fromJS([]))

  // Do not replace existing filters to keep flags like inverted
  return fromJS(items.map(item => {
    const found = currentFilters.find(f => f.get('id') === item.id)

    return found || mapSelectedItem(item, fieldNameOverride || field)
  }))
}

const tonalityKeys = ['negative', 'neutral', 'positive', 'unknown']
const specialFilters = ['booleans', 'geoBoundingBox', 'tonalities', 'similarity']

const mapTonalities = tonalities => {
  const result = []

  tonalityKeys.forEach(tonality => {
    if (tonalities[tonality]) {
      result.push({
        id: tonality,
        name: tonality,
        field: SearchFields.TONALITIES
      })
    }
  })

  return fromJS(result)
}

const addNewsQuery = (state, { query, type, prefix }) => {
  const toAdd = mapQueryStringQuery(query, type, query, prefix)

  if (!state.get(`${type}Queries`).find(q => q.get('value') === toAdd.get('value'))) {
    return state.set(`${type}Queries`, state.get(`${type}Queries`).push(toAdd))
  }

  return state
}

const buildSearchPath = (field, index = null) => {
  let searchPath = [field, index]

  if (field.startsWith('similarity')) {
    searchPath = ['similarity', camelcase(field.replace('similarity', '')), index]
  }

  if (isAiEntityKey(field)) {
    searchPath = ['aiEntities', index]
  }

  return searchPath.filter(f => f !== null)
}

export default handleActions({
  [UserActions.setUser]: (state, { payload: user }) => state.setIn(['booleans', 'globalClusters'], user.globalClusterDefault),
  [UserActions.setField]: (state, { payload: { field, value } }) => {
    if (field === 'globalClusterDefault') {
      return state.setIn(['booleans', 'globalClusters'], value)
    }

    return state
  },
  [FilterActions.addNewsQuery]: (state, { payload }) => addNewsQuery(state, payload),
  [FilterActions.addNewsQueries]: (state, { payload: queries }) => {
    let newState = state

    queries.forEach(query => {
      newState = addNewsQuery(newState, query)
    })

    return newState
  },

  [FilterActions.setSelectedFilters]: (state, { payload }) => {
    const {
      tonalities,
      booleans,
      geoBoundingBox,
      similarity
    } = payload

    const newFilters = Object.keys(payload)
      .filter(key => specialFilters.indexOf(key) === -1)
      .reduce((acc, key) => ({ ...acc, [key]: mapSelectedItems(payload[key], state, key) }), {})

    return state
      .merge(newFilters)
      .merge({
        tonalities: mapTonalities(tonalities, state),
        booleans: fromJS(booleans),
        geoBoundingBox: geoBoundingBox || defaultGeoBoundingBox,

        similarity: state.get('similarity').merge({
          ccdCampaigns: mapSelectedItems(similarity.ccdCampaigns, state.get('similarity'), 'ccdCampaigns', 'similarityCcdCampaigns')
        })
      })
  },
  [FilterActions.addFilters]: (state, { payload: filters }) => {
    let newState = state
    filters.forEach(({ filter, field }) => {
      if (!filter) {
        return
      }

      const toAdd = mapAddedItem(filter, field)

      const searchPath = buildSearchPath(field)

      if (newState.getIn(searchPath) && !newState.getIn(searchPath).find(item => item.get('id') === toAdd.get('id'))) {
        newState = newState.updateIn(searchPath, values => values.push(toAdd))
      }
    })

    return newState
  },
  [FilterActions.replaceFilters]: (state, { payload: filters }) => {
    let newState = filters.reduce((acc, { field }) => {
      const searchPath = buildSearchPath(field)

      if (state.getIn(searchPath)) {
        return acc.setIn(searchPath, initialState.getIn(searchPath))
      }

      return acc
    }, state)

    filters.forEach(({ filter, field }) => {
      if (!filter) {
        return
      }

      const toAdd = mapAddedItem(filter, field)

      const searchPath = buildSearchPath(field)

      if (newState.getIn(searchPath) && !newState.getIn(searchPath).find(item => item.get('id') === toAdd.get('id'))) {
        newState = newState.updateIn(searchPath, values => values.push(toAdd))
      }
    })

    return newState
  },
  [FilterActions.resetFilterFields]: (state, { payload: fields }) => {
    let newState = state
    fields.forEach(field => {
      newState = newState.set(field, initialState.get(field))
    })

    return newState
  },
  [FilterActions.setFilter]: (state, { payload: { field, values } }) => state.set(field, values),
  [FilterActions.setBoolean]: (state, { payload: { field, value } }) => state.setIn(['booleans', field], value),
  [FilterActions.changeDate]: (state, { payload: { dateFrom, dateTo, dateRange } }) => {
    const { dateInterval, allowedDateIntervals } = calculateIntervals(state.get('dateInterval'), dateFrom, dateTo)
    const dateType = state.get('dateType')

    let newDateFrom = dateFrom

    if (newDateFrom && dateType === 'mediareview') {
      newDateFrom = moment(newDateFrom).startOf('day').toDate()
    }

    let newDateTo = dateTo

    if (newDateTo && dateType === 'mediareview') {
      newDateTo = moment(newDateTo).endOf('day').toDate()
    }

    return state.merge({
      dateFrom: newDateFrom,
      dateTo: newDateTo,
      dateRange: dateRange || datesToRange(dateFrom, dateTo),
      dateInterval,
      allowedDateIntervals
    })
  },
  [FilterActions.changeDateType]: (state, { payload: dateType }) => state.set('dateType', dateType),
  [FilterActions.changeDateInterval]: (state, { payload: interval }) => state.set('dateInterval', interval),
  [FilterActions.removeFilter]: (state, { payload: { field, index } }) => {
    if (List.isList(state.get(field))) {
      return state.update(field, list => list.delete(index))
    }

    if (field === 'booleans') {
      return state.setIn([field, index], false)
    }

    if (field === 'geoBoundingBox') {
      return state.set(field, initialState.get('geoBoundingBox'))
    }

    if (field.startsWith('similarity')) {
      return state.deleteIn(['similarity', camelcase(field.replace('similarity', '')), index])
    }

    if (isAiEntityKey(field)) {
      return state.deleteIn(['aiEntities', index])
    }

    return state.delete(field)
  },
  [FilterActions.invertFilter]: (state, { payload: { field, index } }) => {
    if (isAiEntityKey(field)) {
      return state.updateIn(['aiEntities', index, 'inverted'], inverted => !inverted)
    }

    if (List.isList(state.get(field))) {
      return state.updateIn([field, index, 'inverted'], inverted => !inverted)
    }

    return state
  },

  [FilterActions.updateFilter]: (state, { payload: { filter, index } }) => {
    if (List.isList(state.get(filter.get('field')))) {
      return state.setIn([filter.get('field'), index], filter.set('updated', true))
    }

    return state
  },

  [FilterActions.setAnalysisFilter]: (state, { payload: { field, value } }) => state.setIn(['analysis', field], value),

  [FilterActions.setSimilarityFilter]: (state, { payload: { field, value } }) => {
    const newValue = value.map(v => mapAddedItem(v, camelcase(`similarity_${field}`)))

    return state.setIn(['similarity', field], newValue)
  },

  [FilterActions.toggleStatementTonality]: (state, { payload: tonality }) => (
    state.updateIn(['analysis', 'statementTonalities'], tonalities => {
      if (tonalities.some(t => t.get('id') === tonality.get('id'))) {
        return tonalities.filter(t => t.get('id') !== tonality.get('id'))
      }

      return tonalities.push(tonality)
    })
  ),

  [FilterActions.resetAnalysisCodesFilter]: state => (
    state.update('analysis', analysis => analysis.merge(fromJS({
      selectedCodes: [],
      restrictedCodes: [],
      excludedCodes: [],
      groupedCodes: []
    })))
  ),

  [FilterActions.swapAnalysisCodeSelectionAndGrouping]: state => (
    state.update('analysis', analysis => analysis.merge(fromJS({
      selectedCodes: analysis.get('groupedCodes'),
      groupedCodes: analysis.get('selectedCodes')
    })))
  ),

  [FilterActions.resetFilters]: (state, { payload: globalClusterDefault }) => (
    state.merge(resettableFilters)
      .setIn(['analysis', 'useMediaReviewCodes'], state.getIn(['analysis', 'useMediaReviewCodes']))
      .setIn(['booleans', 'globalClusters'], globalClusterDefault)
  ),

  [ConfigActions.setStatics]: (state, { payload }) => (
    state.setIn(['analysis', 'useMediaReviewCodes'], !(payload.analysisCodes || []).some(c => c.analysis))
  ),

  [AppActions.resetState]: () => initialState
}, initialState)
